import Vue from "vue";
import Vuex from "vuex";
import callBerServiceApi from "@/js/services/berServices";
//import Routes from "@/js/services/berServicesRoutes";

Vue.use(Vuex);

let state = {
  isBuyAble: true,
  isBuyAbleChecked: false,
  isBuyAbleCheckedInfo: null,
  isPayAble: false,
  isBought: false,
  product_id: null,
  data: {},
  product_number: null,
  loading: true,
  globalError: "",
  result: {},
  customer: {},
  product_prices: [],
  customerDateTime: {
    set: false
  },
  hasValidCoupon: false,
  couponCode: "",
  total_products: 0,
  total_price: 0,
  discount: 0,
  token: null,
  confirmation: {},
  counting_field: "number_articles", // hardcoded
  adyenData: null
};

const queryString = window.location.search;
const urlSearchParams = new URLSearchParams(queryString);
const adyenSessionId = urlSearchParams.get("sessionId");
const adyenRedirectResult = urlSearchParams.get("redirectResult");
let stateFromLocalStorage = null;

if (localStorage.getItem("store")) {
  stateFromLocalStorage = JSON.parse(localStorage.getItem("store"));
}

// check if URL contains session ID from Adyen
// store session into local storage and reload page without query params
if (adyenSessionId && adyenRedirectResult) {
  stateFromLocalStorage.adyenData.session.id = adyenSessionId;
  stateFromLocalStorage.adyenData.redirectResult = adyenRedirectResult;
  localStorage.setItem("store", JSON.stringify(stateFromLocalStorage));
  console.log("save redirectResult and reload without query");
  window.location.href = `${window.location.origin}${window.location.pathname}`;
  // if local storage contains redirectResult use this as initial state
} else if (stateFromLocalStorage?.adyenData?.redirectResult) {
  console.log("set state from store");
  state = stateFromLocalStorage;
} else {
  localStorage.removeItem("store");
}

const store = new Vuex.Store({
  state,
  actions: {
    GET_DATA(context, { route, parameter, i18n }) {
      this.route = route;
      this.result = "";
      context.commit("update_loading", true);
      this.responder = function(response) {
        if (response.loading) {
          // loading animation
          context.loading = true;
        } else {
          // response with data
          context.loading = false;
          // todo: data should not be an array
          if (!response.data) {
            if (response.error) {
              context.commit("SET_DATA", {
                data: { error: true, errortext: response.error }
              });
            } else {
              context.commit("SET_DATA", { data: { error: true } });
            }
          } else if (response.data[0]) {
            context.commit("SET_DATA", { data: response.data[0] });
          } else {
            context.commit("SET_DATA", response);
          }
          context.commit("update_loading", false);
        }
      };

      callBerServiceApi(route, parameter, this.responder, i18n);
    },
    CHECK_BUYABILITY(context, { route = "productBuyability", i18n }) {
      this.route = route;
      this.result = "";
      const parameter = {
        id: context.state.product_id,
        customer: context.state.customer,
        product_prices: context.state.product_prices
      };

      context.commit("update_loading", true);
      this.responder = function(response) {
        if (response.loading) {
          context.loading = true;
        } else if (
          response.error &&
          response.error.code &&
          response.error.code === "inputParameterOutsideValidRange"
        ) {
          context.state.isBuyAbleCheckedInfo = response.error;
          context.commit("update_loading", false);
        } else if (response.error) {
          context.commit("update_loading", false);
          context.commit("update_setGlobalError", response.error);
        } else {
          context.loading = false;
          context.commit("GET_BUY_STATUS", response);
          context.commit("update_loading", false);

          if (response.product_buyability) {
            document
              .querySelector(".bers-product-time__buyability-button")
              .scrollIntoView({ behavior: "smooth" });
            if (
              document.querySelector(".bers-form-input input:first-child")
                .value === ""
            ) {
              setTimeout(() => {
                document
                  .querySelector(".bers-form-input input:first-child")
                  .focus();
              }, 500);
            }
          }
        }
      };

      callBerServiceApi(route, parameter, this.responder, i18n);
    },
    GENERELL_ERROR(context, error) {
      // TODO: handling generelle errors
      // e.g. error.code === generalError
      context.errorMessage = error.text;
      console.log("generell errorCode:", error.code, "origin", error.origin);
    }
  },
  mutations: {
    SET_DATA(state, response) {
      state.data = response.data;
      // to select first product and set id - fix
      state.product_number = 0;
      if (state.data.products) {
        state.product_id = state.data.products[state.product_number].product_id;
      }
    },
    GET_BUY_STATUS(state, response) {
      this.commit("update_buyablechecked", response.product_buyability);

      if (response.note) {
        state.isBuyAbleCheckedInfo = response.note;
      } else if (response.error) {
        response.error.origin = "GET_BUY_STATUS";
        this.dispatch("GENERELL_ERROR", response.error);
        state.isBuyAbleCheckedInfo = response.error;
        response.note = "!" + response.error.text;
      } else {
        response.note = null;
      }
    },
    update_hasValidCoupon(state, payload) {
      state.hasValidCoupon = payload;
    },
    update_couponCode(state, payload) {
      state.couponCode = payload;
    },
    update_total_price(state, payload) {
      state.isBuyAbleChecked = false;
      state.total_price = payload;
    },
    update_total_products(state, payload) {
      state.total_products = payload;
    },
    update_product_prices(state, payload) {
      if (!state.product_prices[payload.index]) {
        state.product_prices[payload.index] = {};
      }
      state.product_prices[payload.index][payload.identifier] = payload.value;
      if (payload.identifier === state.counting_field) {
        let sumItems = 0,
          value = 0;
        for (let index = 0; index < state.product_prices.length; index++) {
          value = state.product_prices[index][state.counting_field];
          if (parseInt(value, 10) > 0) {
            sumItems += value;
          }
        }
        this.commit("update_total_price", null);
        this.commit("update_total_products", sumItems);
        state.isBuyAbleChecked = false;
        state.isBuyAbleCheckedInfo = "";
        if (sumItems > 0 && state.customer.ticket_valid_from) {
          state.customerDateTime.set = true;
        } else {
          state.customerDateTime.set = false;
        }
      }
    },
    update_product_type(state, payload) {
      state.customer.product_type = payload;
    },
    update_customer(state, payload) {
      if (payload.key === "time" || payload.key === "date") {
        state.isBuyAbleChecked = false;
        state.isBuyAbleCheckedInfo = "";
        state.customerDateTime[payload.key] = payload.value;
        this.commit("update_date_time");
      } else if (payload.key === "entry_type") {
        state.isBuyAbleChecked = false;
        state.isBuyAbleCheckedInfo = "";

        state.customer[payload.key] = payload.value;
      } else {
        state.customer[payload.key] = payload.value;
      }
    },
    update_date_time(state) {
      const value =
        state.customerDateTime.date + " " + state.customerDateTime.time;
      this.commit("update_customer", {
        key: "ticket_valid_from",
        value: value
      });

      if (state.total_products > 0) {
        state.customerDateTime.set = true;
      }
    },
    update_loading(state, payload) {
      state.loading = payload;
    },
    update_buyablechecked(state, payload) {
      state.isBuyAbleChecked = payload;
    },
    update_setPayAble(state, payload) {
      state.isPayAble = payload;
    },
    update_setBuyAble(state, payload) {
      state.isBuyAble = payload;
    },
    update_setBought(state, payload) {
      state.isBought = payload;
    },
    update_token(state, payload) {
      state.token = payload;
    },
    update_confirmation(state, payload) {
      state.confirmation = payload;
    },
    update_setAmount(state, payload) {
      state.total_price = payload;
    },
    update_setSum(state, payload) {
      state.total_products = payload;
    },
    update_setDiscount(state, payload) {
      state.discount = payload;
    },
    update_setGlobalError(state, payload) {
      state.globalError = payload;
    },
    update_setAdyenData(state, payload) {
      state.adyenData = payload;
    },
    update_storeBackup(state) {
      localStorage.setItem("store", JSON.stringify(state));
    },
    update_resetBackup() {
      localStorage.removeItem("store");
    }
  },
  getters: {
    getData: state => {
      return state.data;
    },
    getProduct: state => {
      return state.data.products[state.product_number];
    },
    getProducts: state => {
      return state.data.products;
    },
    getCategory: state => {
      return state.data.category;
    },
    getProductQuantity: state => {
      return state.data.products[state.product_number];
    },
    getProductTypes: state => {
      return state.data.products[state.product_number].product_types;
    },
    getProductPriceList: state => {
      return state.data.products[state.product_number].product_prices;
    },
    getPriceInputs: state => {
      return state.data.products[state.product_number].product_inputs;
    },
    customerInputs: state => {
      return state.data.products[state.product_number].customer_inputs;
    },
    getTotalPrice: state => {
      return state.total_price;
    },
    getDiscount: state => {
      return state.discount;
    },
    getTotalProducts: state => {
      return state.total_products;
    },
    getIsBuyAble: state => {
      return state.isBuyAble;
    },
    getIsPayAble: state => {
      return state.isPayAble;
    },
    getBuyAbleChecked: state => {
      return state.isBuyAbleChecked;
    },
    getBuyAbleCheckedInfo: state => {
      return state.isBuyAbleCheckedInfo;
    },
    getIsBought: state => {
      return state.isBought;
    },
    getLoading: state => {
      return state.loading;
    },
    getCustomer: state => {
      return state.customer;
    },
    getCustomerDateTime: state => {
      return state.customerDateTime;
    },
    getProductId: state => {
      return state.product_id;
    },
    getProductData: state => {
      const productData = {
        customer: state.customer,
        product_id: state.product_id,
        product_prices: state.product_prices
      };
      return productData;
    },
    getProductPrices: state => {
      return state.product_prices;
    },
    getReadyForCheck: state => {
      return state.customerDateTime.set;
    },
    getToken: state => {
      return state.token;
    },
    getAmount: state => {
      return state.total_price;
    },
    getSum: state => {
      return state.total_products;
    },
    getConfirmation: state => {
      return state.confirmation;
    },
    getPossibleDateTimes: state => {
      return state.data.category.opening_time;
    },
    getMinutesBeforeClosing: state => {
      return state.data.category.minutes_buyable_before_closing;
    },
    getGlobalError: state => {
      return state.globalError;
    },
    getHasValidCoupon: state => {
      return state.hasValidCoupon;
    },
    getCouponCode: state => {
      return state.couponCode;
    },
    getAdyenData: state => {
      return state.adyenData;
    }
  }
});

// store.subscribe((mutation, state) => {
// localStorage.setItem("store", JSON.stringify(state));
// });

export default store;
