<template>
  <div class="bers-form-checkbox">
    <div class="bers-form-checkbox__wrapper">
      <input
        class="bers-form-checkbox__input"
        :class="{ 'bers-form-checkbox__input--error': errorstatus }"
        :type="type"
        :placeholder="properties.text"
        :id="identifier"
        :required="mandatory"
        @change="handleInput($event)"
      />
      <span class="bers-form-checkbox__checkbox"></span>
      <label
        class="bers-form-checkbox__label"
        :for="identifier"
        v-html="properties.title"
      >
      </label>
      <!--<small
      v-if="errorstatus"
      class="bers-form-checkbox__error-text"
      v-text="backendErrorMessage || properties.error_text"
    ></small>-->
    </div>
  </div>
</template>

<script>
export default {
  name: "Checkbox",
  props: {
    identifier: {
      type: String,
      default: "identifier"
    },
    errorstatus: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: "text"
    },
    mandatory: {
      type: Boolean,
      default: false
    },
    properties: {
      title: {
        type: String,
        default: "title"
      },
      text: {
        type: String,
        default: "text"
      },
      errorTitle: {
        type: String,
        default: "errorTitle"
      },
      errorText: {
        type: String,
        default: "errorText"
      }
    },
    backendErrorMessage: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      error: false
    };
  },
  methods: {
    handleInput(event) {
      let element = event.currentTarget;
      if (element.value === "on") {
        element.value = 1;
      } else if (element.value === "off") {
        element.value = 0;
      }
      this.$store.commit("update_customer", {
        key: element.id,
        value: element.value
      });
    }
  }
};
</script>

<style lang="scss">
.bers-form-checkbox {
  margin: 10px 0;
  padding: 6px 0;
  width: 100%;
}

.bers-form-checkbox__wrapper {
  cursor: pointer;
  display: block;
  font-size: 16px;
  line-height: 20px;
  padding-left: 22px;
  position: relative;
  user-select: none;
}

.bers-form-checkbox__input {
  cursor: pointer;
  height: 20px;
  left: 0;
  opacity: 0;
  margin: 0;
  position: absolute;
  top: 0;
  width: 34px;
  z-index: 1;

  &.bers-form-checkbox__input--error ~ .bers-form-checkbox__checkbox {
    border: 1px solid $color-bers-red;
  }

  &.bers-form-checkbox__input--error ~ .bers-form-checkbox__label {
    color: $color-bers-red;
  }
}

.bers-form-checkbox__checkbox {
  background-color: transparent;
  border: 1px solid $color-bers-grey-medium;
  left: 0;
  height: 12px;
  position: absolute;
  top: 4px;
  width: 12px;
}

.bers-form-checkbox__checkbox:after {
  background-color: $color-bers-red-dark;
  content: "";
  display: none;
  height: 8px;
  left: 1px;
  top: 1px;
  position: absolute;
  width: 8px;
}

.bers-form-checkbox__input:checked ~ .bers-form-checkbox__checkbox:after {
  display: block;
}

.bers-form-checkbox__wrapper a {
  color: currentColor;
  text-decoration: underline;
}

.bers-form-checkbox__error-text {
  color: $color-bers-red;
  display: block;
  font-size: 12px;
  line-height: 18px;
  left: 12px;
  position: absolute;
  top: -18px;
}

.bers-fieldset--deactived {
  & .bers-form-checkbox__checkbox:after {
    background-color: $color-bers-grey-light;
  }
}
</style>
