<template>
  <div class="bers-form-date">
    <button
      class="bers-form-date__button bers-form-date__button--decrease"
      :disabled="decreaserStepperDeactivated"
      @click="decreaseProductDate($event)"
    >
      <span
        class="bers-form-date__button-icon"
        :class="
          decreaserStepperDeactivated
            ? 'bers-form-date__button-icon--deactivated'
            : ''
        "
      ></span>
    </button>
    <label class="bers-form-date__label">
      {{ i18n("labelChooseDate") }}
    </label>
    <div class="bers-form-date__icon">
      <svg
        id="icons_calendar"
        data-name="icons/calendar"
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
      >
        <path
          d="M14.2,15.935H0V4.622H14.2V15.935ZM1.251,5.925v8.707h11.7V5.823H1.251Zm6.41,7.737V12.053H9.244v1.609Zm-5.362,0V12.053H3.882v1.609Zm2.681-.026V12.053H6.563v1.583Zm0-2.63V9.4H6.563v1.609Zm-2.681,0V9.4H3.882v1.609Zm5.362-.026V9.4H9.244V10.98Zm2.656-.025V9.372h1.507c.076,0,.1,0,.1.076v1.507Zm.025-2.5V6.844h1.583V8.452Zm-2.706,0V6.844H9.116c.051,0,.128-.025.128.076V8.452ZM4.98,8.427V6.844H6.563V8.427ZM14.2,4.162H0V1.686H1.455V.664a.709.709,0,0,1,.2-.536.614.614,0,0,1,.308-.1.517.517,0,0,1,.2.045.47.47,0,0,1,.306.46V1.66H4.01V.562A.509.509,0,0,1,4.52,0a.526.526,0,0,1,.51.562v1.1H6.563V.638A.9.9,0,0,1,6.614.357.5.5,0,0,1,7.088.023l.062,0a.573.573,0,0,1,.46.46,1.549,1.549,0,0,1,.026.357v.869H9.167V.945A3.113,3.113,0,0,1,9.193.46a.467.467,0,0,1,.46-.409l.05,0a.455.455,0,0,1,.461.385,3.012,3.012,0,0,1,.026.485v.767H11.7c.025-.026.025-.051.025-.1V.536a.5.5,0,0,1,.332-.485.486.486,0,0,1,.192-.042.446.446,0,0,1,.319.144.6.6,0,0,1,.178.435v1.1H14.2V4.162h0Zm-2.68-.945a.737.737,0,0,0,.707.491.909.909,0,0,0,.11-.006A.83.83,0,0,0,13,3.116a.782.782,0,0,0-.256-.817V2.63a.505.505,0,0,1-.485.563.544.544,0,0,1-.536-.562V2.324A.794.794,0,0,0,11.516,3.217Zm-2.578,0a.734.734,0,0,0,.707.491.909.909,0,0,0,.11-.006.83.83,0,0,0,.664-.587.78.78,0,0,0-.256-.817V2.63a.505.505,0,0,1-.485.563.544.544,0,0,1-.535-.562V2.324A.794.794,0,0,0,8.938,3.217Zm-2.554,0a.737.737,0,0,0,.707.491A.909.909,0,0,0,7.2,3.7a.83.83,0,0,0,.664-.587A.78.78,0,0,0,7.61,2.3V2.63a.505.505,0,0,1-.485.563.544.544,0,0,1-.536-.562V2.324A.794.794,0,0,0,6.384,3.217Zm-2.553,0a.734.734,0,0,0,.707.491.909.909,0,0,0,.11-.006.833.833,0,0,0,.664-.587A.782.782,0,0,0,5.056,2.3V2.63a.505.505,0,0,1-.485.563.544.544,0,0,1-.536-.562V2.324A.794.794,0,0,0,3.83,3.217Zm-2.553,0a.734.734,0,0,0,.707.491.909.909,0,0,0,.11-.006.829.829,0,0,0,.663-.587A.781.781,0,0,0,2.5,2.3V2.63a.505.505,0,0,1-.486.563.544.544,0,0,1-.536-.562V2.324A.752.752,0,0,0,1.277,3.217Z"
          transform="translate(1 0)"
          fill="#b0b3b4"
        />
      </svg>
    </div>
    <input
      ref="date"
      class="bers-form-date__input"
      type="text"
      :rawvalue="realdate"
      :min="min"
      :max="max"
      disabled
      @change="update"
    />
    <!-- This span or another tag should hold the date-->
    <span class="bers-form-date__date"></span>
    <button
      class="bers-form-date__button bers-form-date__button--increase"
      :disabled="increaserStepperDeactivated"
      @click="increaseProductDate($event)"
    >
      <span
        class="bers-form-date__button-icon"
        :class="
          increaserStepperDeactivated
            ? 'bers-form-date__button-icon--deactivated'
            : ''
        "
      ></span>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      default: "2020-09-30"
    },
    min: {
      default: "2020-09-30"
    },
    max: {
      default: "2020-10-31"
    },
    allopeningtime: {
      type: Object
    }
  },
  data() {
    return {
      decreaserStepperDeactivated: true,
      increaserStepperDeactivated: false,
      realdate: ""
    };
  },
  methods: {
    update(e) {
      this.$store.commit("update_customer", {
        key: "date",
        value: e.target.value
      });
    },
    decreaseProductDate(event) {
      event.preventDefault();
      const newValue = this.prev(this.realdate);
      this.realdate = newValue;
      this.$refs.date.value = this.setDate(newValue);
      this.$store.commit("update_customer", {
        key: "date",
        value: newValue
      });
    },
    increaseProductDate(event) {
      event.preventDefault();
      const newValue = this.next(this.realdate);
      this.realdate = newValue;
      this.$refs.date.value = this.setDate(newValue);
      this.$store.commit("update_customer", {
        key: "date",
        value: newValue
      });
    },
    next(theCurrentItem) {
      let keys = Object.keys(this.allopeningtime);
      let nextIndex = keys.indexOf(theCurrentItem) + 1;
      let nextItem = keys[nextIndex];
      this.decreaserStepperDeactivated = false;
      if (nextIndex + 1 === keys.length) {
        this.increaserStepperDeactivated = true;
      } else {
        this.increaserStepperDeactivated = false;
      }
      if (nextItem) {
        return nextItem;
      } else {
        return theCurrentItem;
      }
    },
    prev(theCurrentItem) {
      let keys = Object.keys(this.allopeningtime);
      let nextIndex = keys.indexOf(theCurrentItem) - 1;
      let nextItem = keys[nextIndex];
      this.increaserStepperDeactivated = false;
      if (nextIndex < 1) {
        this.decreaserStepperDeactivated = true;
      } else {
        this.decreaserStepperDeactivated = false;
      }
      if (nextItem) {
        return nextItem;
      } else {
        return theCurrentItem;
      }
    },
    setDate(date) {
      const event = new Date(
        Date.UTC(
          date.substr(0, 4),
          date.substr(5, 2) - 1,
          date.substr(8, 2),
          date.substr(11, 2),
          0,
          0
        )
      );
      const options = {
        year: "numeric",
        month: "numeric",
        day: "numeric"
      };
      if (this.i18n() === "de") {
        return event.toLocaleDateString("de-DE", options);
      } else {
        return event.toLocaleDateString("en-UK", options);
      }
    }
  },
  mounted() {
    // this.realdate = this.value;
    this.$store.commit("update_customer", { key: "date", value: this.value });

    this.$nextTick(function() {
      this.realdate = this.value;
      this.$refs.date.value = this.setDate(this.value);
    });
  }
};
</script>

<style lang="scss">
.bers-form-date {
  display: flex;
  margin: 0;
  position: relative;
  max-width: 55%;

  @media screen and (min-width: $media-width--tablet) {
    max-width: 50%;
    width: 100%;
  }
}

.bers-form-date__label {
  display: none;
}

.bers-form-date__date {
  display: none;
}

.bers-form-date__icon {
  position: absolute;
  left: 32px;
  top: 8px;
}

.bers-form-date__input {
  appearance: none;
  background: transparent;
  border: 0;
  border-bottom: 1px solid #acb3b8;
  border-radius: 0;
  color: $color-bers-white;
  cursor: pointer;
  display: block;
  font-size: 16px;
  font-weight: 400;
  font-family: "BERSans", sans-serif;
  height: 32px;
  line-height: 32px;
  padding-left: 16px;
  position: relative;
  margin: 0;
  max-width: calc(100% - 60px);
  text-align: center;

  &:disabled {
    color: $color-bers-white;
    cursor: auto;
    opacity: 1;
    pointer-events: none;
  }

  &:focus {
    outline: none;
  }
}

.bers-form-date__button {
  background: transparent;
  border: 0;
  border-radius: 0;
  cursor: pointer;
  outline: none;
  height: 32px;
  width: 30px;

  &.bers-form-date__button--decrease {
    padding: 6px 6px 6px 12px;
  }

  &.bers-form-date__button--increase {
    padding: 6px 12px 6px 6px;
  }

  &:disabled {
    cursor: auto;
    pointer-events: none;
  }
}

.bers-form-date__button-icon {
  border-radius: 1px;
  border-bottom: 3px solid $color-bers-red;
  border-left: 3px solid $color-bers-red;
  display: block;
  height: 12px;
  transform: rotate(45deg);
  width: 12px;

  &--deactivated {
    border-color: $color-bers-grey-medium;
  }
}

.bers-form-date__button--increase {
  & .bers-form-date__button-icon {
    transform: rotate(-135deg);
  }
}
</style>
