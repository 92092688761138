/* route
    has method.GET & data: data are transformed to String
    has route.query: used as GET parameter from data and erase form data

    todo:
    - mock error should not be build for PRD
    - ticket_holder 'ticket_valid_from': '2020-09-09

    todo nice:
    set paramater & query
*/

export default {
  products: {
    api: "payment/products"
  },
  product: {
    api: "payment/product/",
    query: "id"
  },
  producttoken: {
    api: "payment/product-with-token/",
    query: "id"
  },
  category: {
    api: "payment/products",
    category_id: "id"
  },
  token: {
    api: "payment/token"
  },
  productBuyability: {
    api: "payment/product-buyability/",
    method: "POST",
    query: "product_id"
  },
  productBuyabilityCheck: {
    api: "payment/product-buyability-check/",
    method: "POST",
    query: "id"
  },
  productTotalPrice: {
    api: "payment/product-total-price/",
    method: "POST",
    query: "product_id"
  },
  productTotalPriceCheck: {
    api: "payment/product-total-price-check/",
    method: "POST",
    query: "product_id"
  },
  productPayment: {
    api: "payment/product-payment/",
    method: "POST",
    query: "product_id"
  },
  getSessionForAdyen: {
    api: "payment/product-payment/",
    method: "POST",
    query: "product_id",
    apiVersion: "v2"
  }
};
