<template>
  <div class="bers-form-coupon">
    <intro :headline="properties.headline" highlighted="true"></intro>
    <div class="bers-form-input">
      <div class="bers-form-input__wrapper">
        <input
          class="bers-form-input__input"
          :class="[
            { 'bers-form-input__input--error': error },
            { 'bers-form-input__input--empty': isEmpty }
          ]"
          type="text"
          :placeholder="properties.text"
          :id="identifier"
          :required="mandatory"
          @input="debounceInput($event)"
          autocomplete="off"
        />
        <label
          class="bers-form-input__label"
          :for="identifier"
          v-html="properties.title"
        >
        </label>
        <small
          v-if="error"
          class="bers-form-input__error-text"
          v-text="errorText || properties.error_text || backendErrorMessage"
        ></small>
        <small v-if="getCouponStatus" class="bers-form-input__valid-hint">{{
          i18n("couponIsValid")
        }}</small>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import Intro from "../text/Intro";
import Input from "../form/Input";
import callBerServiceApi from "@/js/services/berServices";

Vue.component("intro", Intro);
Vue.component("customerInput", Input);

export default {
  name: "Coupon",
  props: {
    identifier: {
      type: String,
      default: "identifier"
    },
    errorstatus: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: "text"
    },
    mandatory: {
      type: Boolean,
      default: false
    },
    properties: {
      title: {
        type: String,
        default: "title"
      },
      text: {
        type: String,
        default: "text"
      },
      error_title: {
        type: String
      },
      error_text: {
        type: String
      }
    },
    backendErrorMessage: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      error: false,
      errorText: undefined,
      isEmpty: true
    };
  },
  computed: {
    getCouponStatus() {
      return this.$store.getters.getHasValidCoupon;
    }
  },
  methods: {
    debounceInput(event) {
      if (this.timeout) {
        window.clearTimeout(this.timeout);
      }
      const element = event.currentTarget;
      this.timeout = window.setTimeout(() => {
        this.handleInput(element);
      }, 350);
    },
    handleInput(element) {
      let value = (element.value = element.value.trim());
      this.isEmpty = value === "";
      if (value.length > this.properties.validation.max_length) {
        element.value = value = value.substr(
          0,
          this.properties.validation.max_length
        );
      }
      if (value.length < this.properties.validation.min_length) {
        this.$store.commit("update_hasValidCoupon", false);
        this.error = false;
      }
      this.$store.commit("update_customer", {
        key: element.id,
        value: value
      });
      if (value.length >= this.properties.validation.min_length) {
        this.validateCoupon(value);
      }
    },
    validateCoupon(couponCode) {
      this.total = 0;
      const parameters = {
        product_prices: this.$store.getters.getProductPrices,
        customer: { coupon_code: couponCode }
      };
      if (!document.querySelector(".bers-product-select.bers-provoke-error")) {
        parameters.product_id = this.$store.getters.getProductId;
      }

      this.$store.commit("update_couponCode", couponCode);

      callBerServiceApi(
        "productTotalPriceCheck",
        parameters,
        this.totalPriceCheckCallback,
        this.i18n
      );
    },
    totalPriceCheckCallback(res) {
      // console.log("totalPriceCheckCallback", res);
      if (res.loading) {
        return;
      }
      if (typeof res.result === "boolean") {
        if (this.$store.getters.getCouponCode === res.coupon_code) {
          if (res.result) {
            this.errorText = "";
          } else {
            this.errorText = res?.note?.text;
          }
          this.$store.commit("update_hasValidCoupon", res.result);
          this.error = !res.result;
        } else {
          console.log("ignore previous fetch");
        }
      } else {
        console.log("totalPriceCheckCallback received an error");
      }
    }
  }
};
</script>

<style lang="scss">
.bers-form-coupon {
  margin-bottom: 10px;
  width: 100%;
}
.bers-form-coupon .bers-form-input__input {
  text-transform: uppercase;
}
.bers-form-coupon .bers-form-input__input:focus + .bers-form-input__label {
  font-size: 12px;
  line-height: 15px;
  top: -14px;
}
.bers-form-coupon .bers-form-input__input--empty {
  & + .bers-form-input__label {
    font-size: 16px;
    line-height: 19px;
    top: 2px;
  }
}
.bers-form-input__valid-hint {
  color: $color-bers-grey-dark;
  display: block;
  font-size: 12px;
  line-height: 18px;
  position: absolute;
  top: 35px;
}
</style>
