<template>
  <fieldset
    class="bers-product-time bers-p-fieldset bers-p-fieldset--fullwidth"
  >
    <div class="bers-product-time__wrapper">
      <div class="bers-product-time__content-wrapper">
        <intro :headline="headline" :introText="introText"></intro>
        <div class="bers-product-time__date-time">
          <timeslotdate
            :value="currentDay"
            :min="currentDay"
            :max="getLastDate"
            :allopeningtime="allOpenningDate"
          ></timeslotdate>
          <timeslottime
            :value="nextEntryHour"
            :allopeningtime="allOpenningDate"
          ></timeslottime>
        </div>
        <button
          class="bers-product-time__buyability-button bers-p-button bers-p-button--dark"
          @click.stop.prevent="checkAvailability"
          :disabled="!getReadyForCheck"
        >
          <span>{{ i18n("availabilityCheckButton") }}</span>
        </button>
      </div>
      <div class="bers-product-time__image-wrapper">
        <img
          class="bers-product-time__image"
          :src="getCategory.category_image"
          alt="No image"
        />
      </div>
    </div>
    <div
      v-if="statusAvailability || statusAvailabilityInfo || statusTimeError"
      class="bers-product-time__buyability bers-p-fieldset"
    >
      <span v-if="statusAvailability" v-text="i18n('availabilityCheckYes')">
      </span>
      <div
        v-else-if="statusAvailabilityInfo || statusTimeError"
        class="bers-product-time__error-field"
      >
        <div class="bers-product-time__error-wrapper">
          <span
            class="bers-product-time__error-text"
            v-text="statusAvailabilityInfo.text"
          >
          </span>
          <span class="bers-product-time__error-mark">!</span>
        </div>
        <small
          class="bers-product-time__error-info"
          v-text="statusTimeErrorText"
        ></small>
      </div>
    </div>
  </fieldset>
</template>

<script>
import Intro from "../text/Intro";
import Timeslottime from "../form/Time";
import Timeslotdate from "../form/Date";

export default {
  name: "ProductTime",
  components: {
    Intro,
    Timeslottime,
    Timeslotdate
  },
  data() {
    return {
      headline: "headlineProductTime",
      introText: "productIntroText",
      currentDay: "",
      currentHour: "",
      nextEntryHour: "",
      allOpenningDate: this.$store.getters.getPossibleDateTimes,
      statusTimeError: false,
      statusTimeErrorText: "statusTimeErrorText"
    };
  },
  computed: {
    getCategory() {
      return this.$store.getters.getCategory;
    },
    getReadyForCheck() {
      return this.$store.getters.getReadyForCheck;
    },
    statusAvailability() {
      return this.$store.getters.getBuyAbleChecked;
    },
    statusAvailabilityInfo() {
      return this.$store.getters.getBuyAbleCheckedInfo;
    },
    getPossibleDateTimes() {
      return this.$store.getters.getPossibleDateTimes;
    },
    getMinutesBeforeClosing() {
      return this.$store.getters.getMinutesBeforeClosing;
    },
    getFirstDate() {
      return Object.keys(this.getPossibleDateTimes)[0];
    },
    getLastDate() {
      const keys = Object.keys(this.getPossibleDateTimes);
      return keys[keys.length - 1];
    },
    getTodayOpeningHour() {
      let min = [];
      let max = [];
      for (const key in this.getPossibleDateTimes) {
        max.push(this.getPossibleDateTimes[key].closing);
        min.push(this.getPossibleDateTimes[key].opening);
      }
      return { opening: Math.min(...min), closing: Math.max(...max) };
    }
  },
  methods: {
    checkAvailability() {
      // time check
      let dateTime = this.$store.getters.getCustomer.ticket_valid_from;
      dateTime = dateTime.replace(" ", "T") + "Z";
      const datecheck = new Date(dateTime);

      if (datecheck !== "Invalid Date" && !isNaN(datecheck)) {
        this.statusTimeError = false;
        this.$store.dispatch("CHECK_BUYABILITY", {
          route: "productBuyabilityCheck",
          i18n: this.i18n
        });
      } else {
        this.statusTimeError = true;
      }
    },
    getCurrentDateTime() {
      const currentDate = new Date();

      this.currentDay = this.getFirstDate;
      if (this.getTodayOpeningHour.opening) {
        const firstHour = this.getTodayOpeningHour.opening / 100;
        if (currentDate.getHours() < firstHour) {
          currentDate.setHours(firstHour);
          currentDate.setMinutes(0);
        }
      }

      currentDate.setMinutes(Math.floor(currentDate.getMinutes() / 15) * 15);
      currentDate.setSeconds(0);
      this.nextEntryHour = currentDate.toTimeString().substr(0, 5);

      this.$store.commit("update_customer", {
        key: "date",
        value: this.currentDay
      });
      this.$store.commit("update_customer", {
        key: "time",
        value: currentDate.toTimeString().substr(0, 8)
      });
    }
  },
  mounted() {
    this.headline = this.i18n(this.headline);
    this.introText = this.i18n(this.introText);
    this.statusTimeErrorText = this.i18n(this.statusTimeErrorText);
    this.getCurrentDateTime();
  }
};
</script>

<style lang="scss">
.bers .bers-product {
  .bers-product-time__wrapper {
    @media screen and (min-width: $media-width--tablet) {
      display: flex;
    }
  }

  .bers-product-time__content-wrapper {
    background-color: $color-bers-blue-dark;
    color: $color-bers-white;
    //height: 500px;
    padding: 36px 25px 30px;
    position: relative;
    word-break: break-word;
    @media screen and (min-width: $media-width--tablet) {
      padding: 30px 40px;
      width: 50%;
    }
    @media screen and (min-width: $media-width--desktop) {
      width: 33.33%;
    }
  }

  .bers-product-time__image-wrapper {
    display: none;
    position: relative;
    @media screen and (min-width: $media-width--tablet) {
      display: block;
      width: 50%;
    }
    @media screen and (min-width: $media-width--desktop) {
      width: 66.66%;
    }
  }

  .bers-product-time__image {
    bottom: 0;
    display: block;
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }

  .bers-product-time__date-time {
    display: flex;
    margin: 30px -12px 50px;
  }

  .bers-product-time__buyability {
    margin-bottom: 0;
    margin-top: 30px;
  }
}

.bers-product-time__error-field {
  color: $color-bers-red;
  font-family: "BER Sans", sans-serif;

  & .bers-product-time__error-wrapper {
    border-bottom: 1px solid $color-bers-red;
    display: flex;
    justify-content: space-between;
    padding: 6px 10px;
    max-width: 320px;

    & .bers-product-time__error-text,
    & .bers-product-time__error-mark {
      font-size: 18px;
      line-height: 22px;
    }

    & .bers-product-time__error-text {
      font-weight: bold;
    }
  }

  & .bers-product-time__error-info {
    font-size: 12px;
    line-height: 18px;
    padding: 0 10px;
  }
}
</style>
