<template>
  <div class="bers-summary">
    <ul class="bers-summary__list" v-if="statusAvailability">
      <li class="bers-summary__list-item">{{ category.category_name }}</li>
      <li class="bers-summary__list-item">{{ formatDate }}</li>
      <li class="bers-summary__list-item">
        {{ i18n("entry") }} {{ formatTime }}
      </li>
    </ul>
    <ul class="bers-summary__list" v-else>
      <li class="bers-summary__list-item">–</li>
      <li class="bers-summary__list-item">–</li>
      <li class="bers-summary__list-item">–</li>
    </ul>
    <table class="bers-summary__table" v-if="statusAvailability">
      <thead class="bers-summary__table-head">
        <tr>
          <th></th>
          <th>{{ i18n("totalSum") }}</th>
          <th>{{ i18n("productPrice") }}</th>
        </tr>
      </thead>
      <tbody class="bers-summary__table-body">
        <tr
          v-for="(item, index, key) in products.product_prices"
          :index="index"
          :key="key"
          class="bers-summary__product"
        >
          <td class="bers-summary__product-name">
            {{ products.product_name }}
          </td>
          <td class="bers-summary__product-amount">x{{ totalProducts }}</td>
          <td class="bers-summary__product-price">
            {{ item.product_price_str }}
          </td>
        </tr>
        <tr v-if="getCouponStatus">
          <td class="bers-summary__product-name">
            {{ i18n("couponRedeemed") }}
          </td>
          <td class="bers-summary__product-amount"></td>
          <td class="bers-summary__product-price">- {{ euro(getDiscount) }}</td>
        </tr>
      </tbody>
      <tfoot class="bers-summary__table-footer">
        <tr class="bers-summary__total">
          <td class="bers-summary__total-title">{{ i18n("totalPrice") }}</td>
          <td></td>
          <td class="bers-summary__total-price">
            <span class="bers-summary__total-price-number">{{ total }}</span>
            <small class="bers-summary__total-price-vat-info">{{
              i18n("productPriceVatHint")
            }}</small>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
import callBerServiceApi from "@/js/services/berServices";

export default {
  data() {
    return {
      total: 0,
      couponDiscount: 0
    };
  },
  props: {
    time: {
      type: String
    },
    date: {
      type: String
    },
    infoText: {
      type: String
    }
  },
  watch: {
    getCouponStatus() {
      this.totalPriceCheck();
    }
  },
  methods: {
    euro(value) {
      return (
        Number(value).toLocaleString(this.i18n(), {
          minimumFractionDigits: 2
        }) + " €"
      );
    },
    totalPriceCheckResult(response) {
      if (response.loading) {
        return;
      } else {
        if (response.total_price) {
          let discount =
            response.total_price.without_discount.value -
            response.total_price.with_discount.value;
          this.$store.commit("update_setDiscount", discount);
          this.total = this.euro(response.total_price.with_discount.value);
          this.errorPriceResponse = false;
        } else {
          this.errorPriceResponse = true;
          this.errorMessage = response.error.text;
        }
        this.$store.commit(
          "update_setAmount",
          response.total_price.with_discount.value
        );
      }
    },
    totalPriceCheckZero() {
      this.total = 0;
    },
    totalPriceCheck() {
      this.total = 0;
      const parameters = {
        product_prices: this.$store.getters.getProductPrices,
        // customer: this.$store.getters.getCustomer
        customer: { coupon_code: this.$store.getters.getCustomer.coupon_code }
      };
      if (!document.querySelector(".bers-product-select.bers-provoke-error")) {
        parameters.product_id = this.$store.getters.getProductId;
      }

      callBerServiceApi(
        "productTotalPriceCheck",
        parameters,
        this.totalPriceCheckResult,
        this.i18n
      );
    },
    setTotal(value) {
      this.total = this.euro(value);
    }
  },
  computed: {
    category() {
      return this.$store.getters.getCategory;
    },
    products() {
      return this.$store.getters.getProduct;
    },
    statusAvailability() {
      return this.$store.getters.getBuyAbleChecked;
    },
    getCouponStatus() {
      return this.$store.getters.getHasValidCoupon;
    },
    totalProducts() {
      const sum = this.$store.getters.getTotalProducts;
      let amount = this.$store.getters.getAmount;
      if (sum > 0 && amount === null) {
        this.totalPriceCheck();
      } else if (sum > 0) {
        this.setTotal(amount);
      } else {
        this.totalPriceCheckZero();
      }
      this.$store.commit("update_setSum", sum);
      return sum;
    },
    getDiscount() {
      return this.$store.getters.getDiscount;
    },
    formatDate() {
      let dateStr = this.date;
      let date = new Date(dateStr);

      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
        time: "numeric"
      };
      if (this.i18n() === "de") {
        return date.toLocaleDateString("de-DE", options);
      } else {
        return date.toLocaleDateString("en-UK", options);
      }
    },
    formatTime() {
      let timeStr = this.time;

      if (this.i18n() === "de") {
        return timeStr.slice(0, 5) + " Uhr";
      } else {
        let timeHour = parseInt(timeStr.slice(0, 3));
        let timeMin = timeStr.substring(3, 5);
        if (timeHour > 12) {
          return timeHour - 12 + ":" + timeMin + " PM";
        } else {
          return timeHour + ":" + timeMin + " AM";
        }
      }
    }
  }
};
</script>

<style lang="scss">
.bers-summary__list {
  list-style: none;
  padding-left: 0;

  & .bers-summary__list-item {
    font-size: 16px;
    line-height: 26px;
  }
}

.bers-summary__table {
  border-collapse: collapse;
  text-align: right;
  width: 100%;

  & th:first-child,
  & td:first-child {
    text-align: left;
    width: 50%;
  }

  @media screen and (min-width: $media-width--tablet) {
    & th:first-child,
    & td:first-child {
      text-align: right;
      width: 70%;
    }
  }
}

.bers-summary__table-head th {
  font-weight: normal;
}

.bers-summary__product td {
  padding: 10px 0 10px;
}

.bers-summary__table-body tr:last-child td {
  padding-bottom: 20px;
}

.bers-summary__table-footer {
  border-top: 1px solid $color-bers-grey-medium;
}

.bers-summary__total {
  font-weight: bold;
  & td {
    padding: 20px 0;
  }
}

.bers-summary__total-title,
.bers-summary__total-price-number {
  font-size: 24px;
  line-height: 29px;
  vertical-align: top;
}

.bers-summary__total-price-vat-info {
  display: block;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
}
</style>
