<template>
  <div class="bers-confirmation bers-p-fieldset">
    <div v-if="info.data">
      <intro
        :headline="i18n('confirmationSubTitle')"
        :introText="i18n('confirmationText')"
      ></intro>
    </div>
    <p v-if="info.error" class="bers-errorbox">
      {{ i18n("errorBuying") }}
      {{ info.error.text }}
    </p>
  </div>
</template>

<script>
import Intro from "./text/Intro";

export default {
  name: "Confirmation",
  components: {
    Intro
  },
  data() {
    return {
      info: {
        error: {
          text: ""
        }
      }
    };
  },
  mounted() {
    this.scrollTopBers();
    this.$store.commit("update_loading", false);
    this.info = this.$store.getters.getConfirmation;
  }
};
</script>
