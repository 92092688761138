/*
  todo:  API switch based on data-endpoint


*/

let apiEndpoint = "https://bsb.berlin-airport.de/shop-api";
if (
  window.location.host === "localhost:8080" ||
  window.location.host === "tstcommercial-api-booking.berlin-airport.de" ||
  window.location.host === "tstber.berlin-airport.de" ||
  window.location.host === "tstwww.berlin-airport.de" ||
  window.location.host === "tstonsite.berlin-airport.de" ||
  window.location.host === "tstmedia-ber.berlin-airport.de" ||
  window.location.host === "tstdanketxl.berlin-airport.de" ||
  window.location.host === "tstwifi-login.berlin-airport.de" ||
  window.location.host === "dev-werbung-fbb.pixelpark.net" ||
  window.location.host === "tstbsb.berlin-airport.de"
) {
  apiEndpoint = "https://tstbsb.berlin-airport.de/shop-api";
}
import routes from "./berServicesRoutes";

const objecttoString = params => {
  return Object.keys(params)
    .map(key => `${key}=${params[key]}`)
    .join("&");
};

export default (route, data = {}, callback, i18n, addAuthPath = true) => {
  let url;

  callback({ loading: true });

  if (!routes[route]) {
    callback({ error: "Route not found" });
    return;
  }

  const apiVersion = routes[route].apiVersion || "v1";

  // Extra path for basic auth for Null-Euro-Ticket
  // That is only needed in the payment routes
  const auth = (addAuthPath && window.location.pathname === "/index-beyer.html") ? "auth/" : "";

  url = `${apiEndpoint}/${apiVersion}/${auth}${routes[route].api}`;

  const options = {
    method: routes[route].method || "GET",
    withCredentials: true,
    credentials: "include",
    headers: {
      "Content-Type": "application/json"
    }
  };

  if (routes[route].query && data[routes[route].query]) {
    url += data[routes[route].query];
  }

  if (data && Object.keys(data).length > 0 && options.method === "POST") {
    options.body = JSON.stringify(data);
    url += "?lang=" + i18n();
  } else if (data && Object.keys(data).length > 0 && options.method === "GET") {
    url += "?" + objecttoString(data) + "&lang=" + i18n();
  } else {
    url += "?lang=" + i18n();
  }

  const FETCH_TIMEOUT = 10000;
  let didTimeOut = false;
  new Promise((resolve, reject) => {
    const timeout = setTimeout(function() {
      didTimeOut = true;
      reject(new Error());
    }, FETCH_TIMEOUT);
    const i18n_that = i18n;
    fetch(url, options)
      .then(response => {
        clearTimeout(timeout);
        if (!didTimeOut) {
          const contentType = response.headers.get("content-type");
          if (contentType && contentType.indexOf("application/json") !== -1) {
            return response.json();
          } else if (response.status !== 200) {
            resolve(response);
            return {
              error: i18n_that("connectionError"),
              status: response.status,
              contentType
            };
          } else {
            resolve(response);
            return {
              error: i18n_that("connectionError"),
              status: response.status,
              contentType
            };
          }
        }
      })
      .then(json => {
        resolve(json);
        if (!json) {
          return;
        }
        // console.log(json);
        setTimeout(function() {
          callback(json);
        }, 800);
      })
      .catch(err => {
        if (didTimeOut) return;
        callback({ error: i18n_that("connectionRequestError"), err });
        resolve(err);
      });
  })
    .then(() => {
      // Request success and no timeout
      didTimeOut = false;
    })
    .catch(() => {
      // Error: response error, request timeout or runtime error
      console.log(callback);

      callback({
        error: i18n("connectionRequestTimeout"),
        timeout: true
      });
    });
};
