<template>
  <div class="bers-form-stepper">
    <div class="bers-form-stepper__input-wrapper">
      <a
        class="bers-form-stepper__button bers-form-stepper__button--decrease"
        :class="{
          'bers-form-stepper__button--disabled':
            updatedValue <= minValue || updatedValue === 1
        }"
        @click="decreaseInputNumber($event)"
      >
        <span class="bers-form-stepper__button-icon">-</span>
      </a>
      <input
        class="bers-form-stepper__input"
        type="number"
        :min="minValue"
        :max="maxValue"
        :value="updatedValue"
        @input="handleInput($event)"
      />
      <a
        class="bers-form-stepper__button bers-form-stepper__button--increase"
        :class="{
          'bers-form-stepper__button--disabled': updatedValue >= maxValue
        }"
        @click="increaseInputNumber($event)"
      >
        <span class="bers-form-stepper__button-icon">+</span>
      </a>
    </div>
    <label class="bers-form-stepper__label"> </label>
  </div>
</template>

<script>
export default {
  data() {
    return {
      updatedValue: this.value
    };
  },
  props: {
    item: Object,
    number: Number,
    value: {
      default: 1
    }
  },
  methods: {
    handleInput: function(event) {
      let element = event.currentTarget;
      if (parseInt(element.value, 10) > parseInt(element.max, 10)) {
        element.value = element.max;
      }
      this.handleValue(element.value);
    },
    handleValue: function(value) {
      this.updatedValue = value;
      this.$store.commit("update_product_prices", {
        index: this.number,
        identifier: this.item.identifier,
        value: parseInt(value)
      });
    },
    decreaseInputNumber(event) {
      event.preventDefault();
      let parent = event.currentTarget.parentNode;
      let input = parent.querySelector(".bers-form-stepper__input");
      input.value--;
      this.updatedValue = input.value;
      this.handleValue(this.updatedValue);
    },
    increaseInputNumber(event) {
      event.preventDefault();
      let parent = event.currentTarget.parentNode;
      let input = parent.querySelector(".bers-form-stepper__input");
      input.value++;
      this.updatedValue = input.value;
      this.handleValue(this.updatedValue);
    }
  },
  mounted() {
    this.$store.commit("update_product_prices", {
      index: this.number,
      identifier: this.item.identifier,
      value: this.value
    });
  },
  computed: {
    maxValue() {
      if (
        this.item.properties &&
        this.item.properties.validation &&
        this.item.properties.validation.max_value
      ) {
        return this.item.properties.validation.max_value;
      }
      return 100;
    },
    minValue() {
      if (
        this.item.properties &&
        this.item.properties.validation &&
        this.item.properties.validation.min_value
      ) {
        return this.item.properties.validation.min_value;
      }
      return 0;
    }
  }
};
</script>

<style lang="scss">
.bers-form-stepper {
  width: 100px;
}

/* hide arrows */

input[type="number"] {
  appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.bers-form-stepper__input-wrapper {
  align-items: center;
  background-color: $color-bers-blue-dark;
  display: flex;
  justify-content: space-between;
  height: 34px;
}

.bers-form-stepper__input {
  background-color: transparent;
  border: none;
  color: $color-bers-white;
  font-family: "BERSans", sans-serif;
  font-size: 18px;
  font-weight: bold;
  height: 34px;
  padding: 0;
  outline: none;
  text-align: center;
  width: 32px;
}

.bers-form-stepper__button {
  align-items: center;
  background-color: transparent;
  border: none;
  color: $color-bers-white;
  cursor: pointer;
  display: flex;
  font-family: "BERSans", sans-serif;
  font-size: 18px;
  font-weight: bold;
  outline: none;
  height: 34px;
  padding: 0 10px;
  width: 34px;

  & .bers-form-stepper__button-icon {
    display: block;
    margin: 0 auto;
  }

  &.bers-form-stepper__button--disabled {
    pointer-events: none;
  }

  &.bers-form-stepper__button--disabled .bers-form-stepper__button-icon {
    display: none;
  }
}
</style>
