<template>
  <div class="bers-payment-summary">
    <intro :headline="i18n('summary')"></intro>
    <bersSummary v-bind="customerDateTime"></bersSummary>
  </div>
</template>

<script>
import Vue from "vue";
import Summary from "../text/Summary";

Vue.component("bersSummary", Summary);

export default {
  name: "ProductSelectSummary",
  computed: {
    statusAvailability() {
      return this.$store.getters.getBuyAbleChecked;
    },
    customerDateTime() {
      return this.$store.getters.getCustomerDateTime;
    }
  }
};
</script>

<style lang="scss">
.bers {
  .bers-product-select-summary {
    margin-bottom: 30px;
    margin-top: 30px;
  }
}
</style>
