<template>
  <div class="bers-form-toggle">
    <div class="bers-form-toggle__switch">
      <label class="bers-form-toggle__label">
        <input
          class="bers-form-toggle__input"
          type="checkbox"
          :checked="checked"
          @input="handleInput()"
        />
        <span class="bers-form-toggle__slider"></span>
      </label>
    </div>
    <div
      class="bers-form-toggle__title"
      v-for="(item, index, key) in items"
      :index="index"
      :class="[
        `bers-form-toggle__title--` + item.value,
        { 'bers-form-toggle__title--active': index === currentIndex }
      ]"
      :key="key"
      @click="handleClick($event)"
    >
      <svg
        v-if="item.value === 'swingdoor'"
        class="bers-form-toggle__swingdoor-icon"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 8.829 14.875"
      >
        <path
          d="M0,10.988A3.877,3.877,0,0,1,1.83,7.693,2.252,2.252,0,0,1,1.363,5.25a9.991,9.991,0,0,1,.876-1.659c.3-.439.694-.591,1.545-.6a2.059,2.059,0,0,1,1.481.446,1.62,1.62,0,0,1,.4,1.2c0,.51-.049,1.562-.113,2.444l1.8.273c.333.058.5.129.6.534.048.191.313,1.568.527,2.674.149.774.3,1.575.348,1.78l-1.4.239A3.886,3.886,0,0,1,0,10.988Zm.989-.023a2.926,2.926,0,1,0,2.92-2.92A2.929,2.929,0,0,0,.989,10.966ZM3,1.262C3.011.375,3.353-.011,4.136,0c.818,0,1.091.387,1.091,1.171C5.216,2.034,4.874,2.4,4.068,2.4,3.307,2.387,3,2.057,3,1.262Z"
          fill="#1e2d37"
        />
      </svg>
      <svg
        v-if="item.value === 'swingdoor'"
        class="bers-form-toggle__buggy-icon"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100.6 116.9"
        style="enable-background:new 0 0 100.6 116.9"
        xml:space="preserve"
      >
        <path
          d="M90.7 116.8c5.7 0 9.9-4.1 9.9-9.9 0-5.6-4.1-9.6-9.9-9.6-1.8 0-3.5.4-4.9 1.2-.1 0-.1.1-.2.1-2.9 1.6-4.7 4.6-4.7 8.4 0 5.7 4.1 9.8 9.8 9.8M30.1 116.9c5.6 0 9.7-4.1 9.7-9.9 0-5.6-4.1-9.6-9.7-9.6-5.7 0-9.9 4-9.9 9.6-.1 5.8 4.1 9.9 9.9 9.9M83.3 88.6L19.2 5.7C14-1.3 8.4-1.6 0 3.4l2.5 6c3.9-1.2 6.6-1.6 8.8.4l12.5 15.8c-4.4 14.4-4.7 36.8.9 52.4 3.7 10.4 10 17.7 19.3 17.7l35.5.2h.2c.9 0 1.8-.5 2.8-1.3 2.5-2 2.7-3.6.8-6z"
        />
      </svg>
      <span v-text="item.name"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Toggle",
  data() {
    return {
      checked: false,
      currentIndex: 0
    };
  },
  props: {
    items: {
      type: Array,
      name: {
        type: String,
        default: "name"
      },
      value: {
        type: String,
        default: "value"
      }
    }
  },
  methods: {
    handleInput() {
      this.checked = !this.checked;
      if (this.checked === false) {
        this.currentIndex = 0;
      } else {
        this.currentIndex = 1;
      }
      this.handleValue(this.currentIndex);
    },
    handleClick(event) {
      let index = parseInt(event.currentTarget.getAttribute("index"));
      if (index !== this.currentIndex) {
        if (index === 0) {
          event.currentTarget.classList.add(this.activeClass);
          this.checked = false;
          this.handleValue(0);
        } else if (index === 1) {
          this.checked = true;
          this.handleValue(1);
        }
        this.currentIndex = index;
      }
    },
    handleValue(value) {
      if (value === 1) {
        this.$store.commit("update_customer", {
          key: "entry_type",
          value: this.items[1].value
        });
      } else {
        this.$store.commit("update_customer", {
          key: "entry_type",
          value: this.items[0].value
        });
      }
    }
  },
  mounted() {
    this.$store.commit("update_customer", {
      key: "entry_type",
      value: this.items[0].value
    });
  }
};
</script>

<style lang="scss">
.bers-form-toggle {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.bers-form-toggle__title {
  color: $color-bers-grey-light;
  cursor: pointer;
  display: block;

  &.bers-form-toggle__title--active {
    color: $color-bers-grey-dark;
  }

  &.bers-form-toggle__title--turnstile {
    order: 1;
    text-align: right;
  }

  &.bers-form-toggle__title--swingdoor {
    order: 3;

    & .bers-form-toggle__swingdoor-icon {
      height: 19px;
      margin-right: 10px;
      vertical-align: text-bottom;
      width: 12px;
    }

    & .bers-form-toggle__buggy-icon {
      height: 19px;
      margin-right: 10px;
      vertical-align: text-bottom;
      width: 16px;
    }
  }
}

.bers-form-toggle__switch {
  order: 2;
}

.bers-form-toggle__label {
  display: inline-block;
  height: 24px;
  margin: 0 10px;
  position: relative;
  width: 48px;
}

.bers-form-toggle__input {
  opacity: 0;
  height: 0;
  width: 0;
}

.bers-form-toggle__slider {
  background-color: $color-bers-white;
  border: 1px solid $color-bers-grey-medium;
  border-radius: 34px;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.bers-form-toggle__slider:before {
  background-color: $color-bers-red-dark;
  border-radius: 50%;
  bottom: 3px;
  content: "";
  height: 16px;
  left: 3px;
  position: absolute;
  transition: all 0.3s ease-in-out;
  width: 16px;
}

.bers-form-toggle__input:checked + .bers-form-toggle__slider:before {
  transform: translateX(24px);
}
</style>
