<template>
  <div class="bers-intro">
    <h3 class="bers-intro__headline" v-html="headline"></h3>
    <p v-html="introText" :class="highlighted ? 'bers-intro__text' : ''"></p>
    <p v-if="infoText" v-html="infoText"></p>
  </div>
</template>

<script>
export default {
  props: {
    headline: {
      type: String,
      default: "No headline"
    },
    introText: {
      type: String
    },
    infoText: {
      type: String
    },
    highlighted: {
      type: String
    }
  }
};
</script>

<style>
.bers-intro__headline {
  font-size: 24px;
  font-weight: bold;
  line-height: 26px;
  margin-bottom: 20px;
}
.bers-intro__text {
  color: #00a0b9;
  font-weight: bold;
}
.bers-fieldset--deactived .bers-intro__text {
  color: #ace0e8;
  font-weight: bold;
}
</style>
