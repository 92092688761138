<template>
  <fieldset class="bers-product-select bers-p-fieldset">
    <table class="bers-product-select__table">
      <thead class="bers-product-select__table-head">
        <tr class="bers-product-select__table-row">
          <th></th>
          <th>{{ i18n("productPrice") }}</th>
          <th>{{ i18n("totalSum") }}</th>
        </tr>
      </thead>
      <tbody
        v-for="(item, index, key) in getProduct.product_prices"
        :index="index"
        :key="key"
        class="bers-product-select__table-body"
      >
        <product-select-prices
          :data="item"
          :number="index"
        ></product-select-prices>
      </tbody>
    </table>

    <div v-if="errorPriceResponse" class="bers-errorbox">
      {{ errorMessage }}
      <br />
      <a href="#" @click.prevent="totalPriceCheck">{{
        i18n("tryBuyabilityAgain")
      }}</a>
    </div>
    <!--<p>
      {{ i18n("totalPrice") }}
      <span>{{ total }}</span>
    </p>
    <p>{{ i18n("totalSum") }} {{ totalProducts }}</p>-->
  </fieldset>
</template>

<script>
import Vue from "vue";
import Intro from "../text/Intro";
import productSelectPrices from "./ProductSelectPrices";
import Increment from "../form/Increment";
//import { debounce } from "@/js/utils/mixin";
Vue.component("intro", Intro);
Vue.component("increment", Increment);
Vue.component("product-select-prices", productSelectPrices);

export default {
  name: "ProductSelect",
  data() {
    return {
      productQuantity: {},
      total: 0,
      errorPriceResponse: false
    };
  },
  methods: {},
  computed: {
    getProductQuantity() {
      return this.$store.getters.getProductQuantity;
    },
    getProduct() {
      return this.$store.getters.getProduct;
    },
    getCategory() {
      return this.$store.getters.getCategory;
    }
  }
};
</script>

<style lang="scss">
.bers-product-select__table {
  border-spacing: 0;
  width: 100%;
}

.bers-product-select__intro-wrapper {
  display: flex;
  justify-content: space-between;
}

.bers-product-select__list {
  margin: 0;
  padding: 0;
}
.bers-product-select__table-row {
  & th {
    font-size: 16px;
    font-weight: normal;
    line-height: 22px;
    padding-bottom: 10px;
    text-align: start;
  }
}
</style>
