<template>
  <div class="bers-payment bers-p-fieldset">
    <paymentSummary v-if="!sessionError"></paymentSummary>
    <button class="bers-payment__back-button" @click="goback">
      <div class="bers-payment__back-button-icon">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.127 14.091">
          <g
            id="icons_link-arrow"
            data-name="icons/link-arrow"
            transform="translate(14.91 14) rotate(180)"
          >
            <path
              id="_"
              data-name="→"
              d="M9.228,14.073l-1.464-1.22,4.087-4.871H0V6.091H11.851L7.764,1.22,9.228,0l5.9,7.032Z"
              transform="translate(-0.217 -0.091)"
              fill="#e61900"
            />
          </g>
        </svg>
      </div>
      <span class="bers-payment__back-button-text">
        {{ i18n("changeSelection") }}
      </span>
    </button>

    <div id="dropin-container"></div>

    <p v-if="paymentError">
      {{ i18n("errorPaymentButton") }}
    </p>
    <p v-if="sessionError">
      {{ i18n("errorBuying") }}
    </p>
    <!--    <a-->
    <!--      href="#"-->
    <!--      class="bers-payment__submit-button bers-p-button bers-p-button&#45;&#45;light"-->
    <!--      :class="{ 'bers-p-button&#45;&#45;disabled': isLoading }"-->
    <!--      id="submit-button"-->
    <!--      :disabled="isLoading"-->
    <!--    >-->
    <!--      <span v-text="i18n('startPayment')"></span-->
    <!--    ></a>-->
  </div>
</template>

<script>
import Vue from "vue";
import AdyenCheckout from "@adyen/adyen-web";
import "@adyen/adyen-web/dist/adyen.css";
import PaymentSummary from "./payment/PaymentSummary";
import callBerServiceApi from "@/js/services/berServices";

Vue.component("paymentSummary", PaymentSummary);

/* Deslint-disable */
export default {
  name: "Payment",
  props: {
    currency: {
      default: "EUR"
    }
  },
  data() {
    return {
      token: null,
      amount: {
        default: "10.0"
      },
      paymentError: false,
      sessionError: false
    };
  },
  methods: {
    loadJS: (url, callback) => {
      const scriptTag = document.createElement("script");
      scriptTag.src = url;
      if (callback) {
        scriptTag.onload = callback;
      }
      document.body.appendChild(scriptTag);
    },
    requestPaymentMethodErr: err => {
      this.info = err;
      // TODO: Error handling
      console.log(err);
    },
    async initAdyen(response) {
      const {
        loading,
        error,
        session,
        client_key,
        transaction_completed,
        environment,
        redirectResult
      } = response;

      if (loading) {
        console.log("still loading adyen session");
      } else if (error) {
        console.log("error loading adyen session", error);
        this.$store.commit("update_loading", false);
        this.sessionError = true;
      } else if (transaction_completed) {
        console.log("transaction was already completed");
        this.$store.commit("update_resetBackup");
        this.$store.commit("update_setAdyenData", null);
        this.$store.commit("update_setPayAble", false);
        this.$store.commit("update_setBought", true);
        this.$store.commit("update_confirmation", {
          data: true
        });
      } else {
        if (!redirectResult) {
          this.$store.commit("update_setAdyenData", {
            environment,
            client_key,
            session: {
              id: session.id,
              data: session.data
            }
          });
          this.$store.commit("update_storeBackup");
        }
        let locale = "en-US";
        if (this.i18n() === "de") locale = "de-DE";

        const adyenOptions = {
          environment,
          clientKey: client_key,
          locale,
          session: {
            id: session.id,
            sessionData: session.data
          },
          onPaymentCompleted: result => {
            this.$store.commit("update_loading", false);

            // https://docs.adyen.com/online-payments/payment-result-codes
            if (result.resultCode === "Error") {
              console.log("error loading adyen payment", result);
              this.$store.commit("update_loading", false);
              this.sessionError = true;
            } else if (
              result.resultCode !== "Cancelled" ||
              result.resultCode !== "Refused"
            ) {
              console.log("adyen payment was successful", result);
              this.$store.commit("update_resetBackup");
              this.$store.commit("update_setAdyenData", null);
              this.$store.commit("update_setPayAble", false);
              this.$store.commit("update_setBought", true);
              this.$store.commit("update_confirmation", {
                data: true
              });
            } else {
              console.log("adyen payment has been cancelled", result);
            }
          },
          onError: (error, component) => {
            console.log("error loading adyen payment");
            console.error(error.name, error.message, error.stack, component);
            this.$store.commit("update_loading", false);
            this.sessionError = true;
          },
          // Any payment method specific configuration. Find the configuration specific to each payment method:  https://docs.adyen.com/payment-methods
          // For example, this is 3D Secure configuration for cards:
          paymentMethodsConfiguration: {
            card: {
              hasHolderName: true,
              holderNameRequired: true
            //   billingAddressRequired: true
            }
          }
        };
        const checkout = await AdyenCheckout(adyenOptions);
        const dropinComponent = checkout
          .create("dropin")
          .mount("#dropin-container");
        if (redirectResult) {
          checkout.submitDetails({ details: { redirectResult } });
        }
        console.log(dropinComponent);
        this.$store.commit("update_loading", false);
        // const submitButton = document.querySelector("#submit-button");
        // let locale = "en_GB";
        // if (this.i18n() === "de") locale = "de_DE";
      }
    },
    initBreadtree() {
      this.$store.commit("update_loading", false);
      //console.log("initBreadtree");

      const submitButton = document.querySelector("#submit-button");
      // eslint-disable-next-line
      // const payload = {
      //   flow: "checkout",
      //   amount: "10.00",
      //   currency: "EUR"
      // };

      let locale = "en_GB";
      if (this.i18n() === "de") locale = "de_DE";
      const braintree = window.braintree;
      const that = this;
      braintree.dropin
        .create({
          authorization: this.token,
          container: "#dropin-container",
          locale: locale,
          paymentOptionPriority: ["paypal", "card"], // , "paypalCredit"
          threeDSecure: true,
          card: {
            flow: "checkout",
            amount: that.amount,
            currency: that.currency
          },
          paypal: {
            flow: "checkout",
            amount: that.amount,
            currency: that.currency
          },
          paypalCredit: {
            flow: "checkout",
            amount: that.amount,
            currency: that.currency
          }
        })
        .then(dropinInstance => {
          submitButton.addEventListener("click", e => {
            e.preventDefault();
            that.$store.commit("update_loading", true);
            // eslint-disable-next-line
              dropinInstance.requestPaymentMethod({
                threeDSecure: {
                  // TODO: error handling: chancel amount
                  amount: that.amount
                }
              })
              .then(payload => {
                // Send payload.nonce to your server
                that.buyNow(payload.nonce);
              })
              .catch(err => {
                // TODO: display error
                that.$store.commit("update_loading", false);
                that.paymentError = true;
                console.log("dropinInstance err", err, that);
                setTimeout(function() {
                  that.paymentError = false;
                }, 5000);
              });
          });
        })
        .catch(err => {
          // Handle any errors that might've occurred when creating Drop-in
          console.error(err);
        });
    },
    buyingIt(response) {
      if (response.loading) {
        //
      } else {
        this.$store.commit("update_setPayAble", false);
        this.$store.commit("update_setBought", true);
        if (response.product_buyability) {
          this.productBuyabilityChecked = response.product_buyability;
          this.$store.commit("update_confirmation", response);
        } else {
          this.$store.commit("update_confirmation", response);
        }
      }
    },
    buyNow(nonce) {
      this.$store.commit("update_loading", true);
      const parameters = this.$store.getters.getProductData;
      if (nonce !== "nononce") parameters.customer.payment_method_nonce = nonce;
      parameters.product_id = this.$store.getters.getProductId;

      callBerServiceApi("productPayment", parameters, this.buyingIt, this.i18n);
    },
    goback() {
      this.$store.commit("update_setAdyenData", null);
      this.$store.commit("update_resetBackup");
      this.$store.commit("update_setBuyAble", true);
      this.$store.commit("update_setPayAble", false);
    },
    getSessionForAdyen() {
      this.$store.commit("update_loading", true);
      const adyenStoreData = this.$store.getters.getAdyenData;

      if (adyenStoreData) {
        console.log("get session for Adyen from store");
        this.$store.commit("update_buyablechecked", true);
        this.initAdyen({ ...adyenStoreData });
      } else {
        const customer = this.$store.getters.getCustomer;
        let coupon_code = this.$store.getters.getHasValidCoupon
          ? this.$store.getters.getCouponCode
          : "";
        const { product_id, product_prices } = this.$store.getters.getProduct;
        const parameters = {
          platform: "web",
          return_url: `${window.location.href}`,
          coupon_code,
          customer,
          products: [
            {
              product_id,
              product_price_id: product_prices[0].product_price_id,
              number_articles: this.$store.getters.getTotalProducts,
              entry_type: customer.entry_type,
              ticket_valid_from: customer.ticket_valid_from
            }
          ]
        };
        callBerServiceApi(
          "getSessionForAdyen",
          parameters,
          this.initAdyen,
          this.i18n
        );
      }
    }
  },
  computed: {
    isLoading() {
      return this.$store.getters.getLoading;
    }
  },
  mounted() {
    this.token = this.$store.getters.getToken;
    this.amount = this.$store.getters.getAmount;
    if (this.amount > 0) {
      // this.loadJS(
      //   "https://js.braintreegateway.com/web/dropin/1.24.0/js/dropin.min.js",
      //   this.initBreadtree
      // );
      this.getSessionForAdyen();
      //this.initAdyen();
    } else {
      this.$store.commit("update_loading", true);
      // this.buyNow("nononce");
      this.getSessionForAdyen();
    }
  }
};
</script>

<style lang="scss">
.bers-payment {
  .bers-payment__back-button {
    align-items: center;
    border: 0;
    background: transparent;
    cursor: pointer;
    display: flex;
    height: 20px;
    margin: 10px 0;
    padding: 0;

    @media screen and (min-width: $media-width--tablet) {
      margin: 30px 0 50px;
    }

    &:focus {
      outline: none;
    }

    & .bers-payment__back-button-icon {
      height: 14px;
      margin-right: 10px;
      width: 14px;

      & svg {
        display: block;
        height: auto;
        width: 100%;
      }
    }

    & .bers-payment__back-button-text {
      display: block;
      font-family: "BER Sans", sans-serif;
      font-size: 16px;
      font-weight: bold;
      line-height: 26px;
    }
  }

  .bers-payment__submit-button {
    margin: 50px auto 0;
    width: 325px;
  }
}
</style>
