<template>
  <div class="bers-form-input">
    <div class="bers-form-input__wrapper">
      <input
        class="bers-form-input__input"
        :class="[
          { 'bers-form-input__input--email': type === 'email' },
          { 'bers-form-input__input--error': errorstatus },
          { 'bers-form-input__input--empty': isEmpty }
        ]"
        :type="getInputType"
        :placeholder="properties.text"
        :id="identifier"
        :required="mandatory"
        @change="handleInput($event)"
        @blur="handleBlur($event)"
      />
      <label
        class="bers-form-input__label"
        :for="identifier"
        v-html="properties.title"
      >
      </label>
      <small
        v-if="errorstatus"
        class="bers-form-input__error-text"
        v-text="backendErrorMessage || properties.error_text"
      ></small>
    </div>
  </div>
</template>

<script>
export default {
  name: "Input",
  props: {
    identifier: {
      type: String,
      default: "identifier"
    },
    errorstatus: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: "text"
    },
    mandatory: {
      type: Boolean,
      default: false
    },
    properties: {
      title: {
        type: String,
        default: "title"
      },
      text: {
        type: String,
        default: "text"
      },
      error_title: {
        type: String
      },
      error_text: {
        type: String
      }
    },
    backendErrorMessage: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      error: false,
      isEmpty: true
    };
  },
  computed: {
    getInputType() {
      switch (this.type) {
        case "string":
          return "text";
        case "integer":
          return "number";
        default:
          return this.type;
      }
    }
  },
  methods: {
    handleInput(event) {
      let element = event.currentTarget;
      if (element.type === "email") {
        this.isEmpty = element.value.trim() === "";
      }
      this.$store.commit("update_customer", {
        key: element.id,
        value: element.value
      });
    },
    handleBlur(event) {
      let element = event.currentTarget;
      this.error = !element.validity.valid;
    }
  }
};
</script>

<style lang="scss">
.bers-form-input {
  max-width: 320px;
  width: 100%;
  @media screen and (min-width: $media-width--tablet) {
    width: 50%;
  }
}

.bers-form-input__wrapper {
  margin: 30px 0 10px;
  position: relative;
  @media screen and (min-width: $media-width--tablet) {
    margin: 30px 0;
  }
}

.bers-form-input__input {
  background: transparent;
  border: 0;
  border-bottom: 1px solid $color-bers-grey-medium;
  color: $color-bers-grey-dark;
  font-family: "BERSans", sans-serif;
  font-size: 16px;
  height: 32px;
  line-height: 19px;
  outline: 0;
  padding: 6px 10px 10px;
  transition: all 0.3s ease-in-out;
  width: 100%;

  &.bers-form-input__input--error {
    border-bottom: 1px solid $color-bers-red;
  }

  &.bers-form-input__input--error + .bers-form-input__label {
    color: $color-bers-red;
  }

  &:focus {
    color: $color-bers-grey-dark;
    outline: 0;
  }

  &::placeholder {
    color: transparent;
  }
}

.bers-form-input__label {
  display: block;
  color: $color-bers-grey-medium;
  font-size: 16px;
  line-height: 19px;
  left: 10px;
  margin-bottom: 0;
  position: absolute;
  top: 2px;
  transition: all 0.2s ease-in-out;
  z-index: 1;
}

.bers-form-input__input:focus + .bers-form-input__label,
.bers-form-input__input:valid + .bers-form-input__label {
  font-size: 12px;
  line-height: 15px;
  top: -14px;
}

.bers-form-input__input--email {
  & + .bers-form-input__label,
  &.bers-form-input__input--empty:focus + .bers-form-input__label {
    font-size: 12px;
    line-height: 15px;
    top: -14px;
  }

  &.bers-form-input__input--empty {
    & + .bers-form-input__label {
      font-size: 16px;
      line-height: 19px;
      top: 2px;
    }
  }
}

/* styles from clientlibs */

.bers-form-input__input:-webkit-autofill,
.bers-form-input__input:-webkit-autofill:hover,
.bers-form-input__input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 30px #fff inset !important;
}

.bers-form-input__input::-webkit-input-placeholder {
  font-size: 18px;
  font-weight: bold;
  color: #acb3b8;
  letter-spacing: 0.39px;
  line-height: inherit;
  overflow: visible;
}

.bers-form-input__input::-moz-placeholder {
  font-size: 18px;
  font-weight: bold;
  color: #acb3b8;
  letter-spacing: 0.39px;
  line-height: inherit;
  overflow: visible;
  opacity: 1;
}

.bers-form-input__input:-ms-input-placeholder {
  font-size: 18px;
  font-weight: bold;
  color: #acb3b8;
  letter-spacing: 0.39px;
  line-height: inherit;
  overflow: visible;
}

.bers-form-input.bers-form-input--checkbox {
  display: flex;
  width: 100%;

  .bers-form-input__input {
    flex: 0 0 20px;
  }

  .bers-form-input__label {
    position: static;
  }
}

.bers-form-input__error-text {
  color: $color-bers-red;
  display: block;
  font-size: 12px;
  line-height: 18px;
  position: absolute;
  top: 35px;
}

.bers-fieldset--deactived {
  & .bers-form-input__input {
    color: $color-bers-grey-medium;
  }

  & input:-webkit-autofill,
  & input:-internal-autofill-selected {
    opacity: 0.25;
  }
}
</style>
