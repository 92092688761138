<template>
  <div class="bers-form-time">
    <button
      class="bers-form-time__button bers-form-time__button--decrease"
      :disabled="decreaserStepperDeactivated"
      @click="decreaseProductTime($event)"
    >
      <span
        class="bers-form-time__button-icon"
        :class="
          decreaserStepperDeactivated
            ? 'bers-form-time__button-icon--deactivated'
            : ''
        "
      ></span>
    </button>
    <label class="bers-form-time__label"></label>
    <div class="bers-form-time__icon">
      <svg
        id="icons_calendar"
        data-name="icons/calendar"
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16.083 16.082"
      >
        <path
          d="M2.358,13.725a8.035,8.035,0,1,1,5.684,2.357A8,8,0,0,1,2.358,13.725ZM3.282,3.281A6.728,6.728,0,1,0,8.042,1.308,6.7,6.7,0,0,0,3.282,3.281Zm6.225,6.98L7.386,8.33V2.854a.654.654,0,1,1,1.308,0v4.9l1.695,1.543a.645.645,0,0,1,.2.333.654.654,0,0,1-1.077.634Z"
          fill="#b0b3b4"
        />
      </svg>
    </div>
    <input
      ref="timeelement"
      class="bers-form-time__input"
      type="text"
      :min="min"
      :max="max"
      @change="update"
      required
      pattern="[0-9]{2}:[0-9]{2}"
    />
    <!--<span class="bers-form-time__time-title">Uhr</span>-->
    <button
      class="bers-form-time__button bers-form-time__button--increase"
      :disabled="increaserStepperDeactivated"
      @click="increaseProductTime($event)"
    >
      <span
        class="bers-form-time__button-icon"
        :class="
          increaserStepperDeactivated
            ? 'bers-form-time__button-icon--deactivated'
            : ''
        "
      ></span>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      default: "10:00"
    },
    min: {
      default: "06:00"
    },
    max: {
      default: "21:30"
    },
    steps: {
      default: 15
    },
    timeslot: {
      type: Object,
      defaut: function() {
        return { opening: 600, closing: 2200 };
      }
    },
    allopeningtime: {
      type: Object
    }
  },
  data() {
    return {
      decreaserStepperDeactivated: false,
      increaserStepperDeactivated: false,
      maxTime: "",
      minTime: ""
    };
  },
  methods: {
    update(e) {
      const time = e.target.value.split(":");
      if (!Number(time[0]) || !Number(time[1])) {
        this.$refs.timeelement.value = this.min;
      } else {
        if (time[1] >= 60) {
          time[0]++;
          time[1] = "00";
        }
        let m = Math.floor(time[1] / 15) * 15;
        if (m < 10) m = "0" + m;
        this.$refs.timeelement.value =
          (time[0] > 9 ? "" : "0") + time[0] + ":" + m;
        this.$store.commit("update_customer", {
          key: "time",
          value: this.$refs.timeelement.value + ":00"
        });
      }
    },
    decreaseProductTime(event) {
      event.preventDefault();
      let cTime = this.timeToNumber(this.$refs.timeelement.value);
      if (cTime === this.minTime) return;
      let nextTime = cTime - parseInt(this.steps, 10);
      if (nextTime < 1000 && nextTime.toString().substr(1, 2) === "85") {
        nextTime -= 40;
      } else if (nextTime.toString().substr(2, 2) === "85") {
        nextTime -= 40;
      }
      this.increaserStepperDeactivated = false;
      if (nextTime === this.minTime) {
        this.decreaserStepperDeactivated = true;
      } else {
        this.decreaserStepperDeactivated = false;
      }
      this.setNewTime(nextTime);
    },
    increaseProductTime(event) {
      event.preventDefault();
      const hourTime = this.$refs.timeelement.value.split(":");
      if (hourTime[1] >= 60) {
        hourTime[0]++;
        hourTime[1] = "00";
      }
      let cTime = this.timeToNumber(hourTime.join(":"));
      if (cTime >= this.maxTime) {
        this.decreaserStepperDeactivated = false;
        this.increaserStepperDeactivated = true;
        return;
      }
      let nextTime = cTime + parseInt(this.steps, 10);

      if (nextTime < 1000 && nextTime.toString().substr(1, 2) === "60") {
        nextTime += 40;
      } else if (nextTime.toString().substr(2, 2) === "60") {
        nextTime += 40;
      }
      this.decreaserStepperDeactivated = false;
      if (nextTime === this.maxTime) {
        this.increaserStepperDeactivated = true;
      } else {
        this.increaserStepperDeactivated = false;
      }
      this.setNewTime(nextTime);
    },
    setNewTime(value) {
      const timeValue = this.numberToTime(value);
      this.$refs.timeelement.value = timeValue;
      this.$store.commit("update_customer", {
        key: "time",
        value: timeValue + ":00"
      });
    },
    timeToNumber(value) {
      const timeSplitted = value.split(":");
      return (
        parseInt(timeSplitted[0], 10) * 100 + parseInt(timeSplitted[1], 10)
      );
    },
    numberToTime(value) {
      let hours = Math.floor(value / 100);
      let minutes = value - hours * 100;
      if (minutes < 10) minutes = "0" + minutes;
      if (hours < 10) hours = "0" + hours;
      return hours + ":" + minutes;
    }
  },
  mounted() {
    this.$store.commit("update_customer", {
      key: "time",
      value: this.value
    });

    this.maxTime = this.timeToNumber(this.max);
    this.minTime = this.timeToNumber(this.min);

    this.$nextTick(function() {
      this.$refs.timeelement.value = this.value;
    });
  }
};
</script>

<style lang="scss">
.bers-form-time {
  display: flex;
  margin: 0;
  position: relative;
  max-width: 50%;
}

.bers-form-time__label {
  display: none;
}

.bers-form-time__icon {
  position: absolute;
  left: 32px;
  top: 8px;
}

.bers-form-time__input {
  appearance: none;
  background: transparent;
  border: 0;
  border-bottom: 1px solid #acb3b8;
  border-radius: 0;
  color: $color-bers-white;
  cursor: pointer;
  display: block;
  font-size: 16px;
  font-weight: 400;
  font-family: "BERSans", sans-serif;
  height: 32px;
  line-height: 32px;
  padding-left: 16px;
  position: relative;
  margin: 0;
  max-width: calc(100% - 60px);
  text-align: center;

  &:focus {
    outline: none;
  }
}

.bers-form-time__time-title {
  display: block;
  position: absolute;
  right: 40px;
  top: 6px;
}

.bers-form-time__button {
  background: transparent;
  border: 0;
  border-radius: 0;
  cursor: pointer;
  outline: none;
  height: 32px;
  width: 30px;
  padding: 6px 10px;

  &.bers-form-time__button--decrease {
    padding: 6px 6px 6px 12px;
  }

  &.bers-form-time__button--increase {
    padding: 6px 12px 6px 6px;
  }

  &:disabled {
    cursor: auto;
    pointer-events: none;
  }
}

.bers-form-time__button-icon {
  border-radius: 1px;
  border-bottom: 3px solid $color-bers-red;
  border-left: 3px solid $color-bers-red;
  display: block;
  height: 12px;
  transform: rotate(45deg);
  width: 12px;

  &--deactivated {
    border-color: $color-bers-grey-medium;
  }
}

.bers-form-time__button--increase {
  & .bers-form-time__button-icon {
    transform: rotate(-135deg);
  }
}
</style>
