var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bers-form-double-input"},[_c('div',{staticClass:"bers-form-input"},[_c('div',{staticClass:"bers-form-input__wrapper"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"bers-form-input__input bers-form-input__input--email",class:[
          { 'bers-form-input__input--error': _vm.isError },
          { 'bers-form-input__input--empty': _vm.isEmptyMail }
        ],attrs:{"type":"email","pattern":_vm.properties.validation.pattern,"placeholder":_vm.properties.text,"id":_vm.identifier,"required":_vm.mandatory},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing)return;_vm.email=$event.target.value}}}),_c('label',{staticClass:"bers-form-input__label",attrs:{"for":_vm.identifier},domProps:{"innerHTML":_vm._s(_vm.properties.title)}}),(_vm.isError)?_c('small',{staticClass:"bers-form-input__error-text",domProps:{"textContent":_vm._s(_vm.getErrorText1)}}):_vm._e()])]),_c('div',{staticClass:"bers-form-input"},[_c('div',{staticClass:"bers-form-input__wrapper"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email2),expression:"email2"}],staticClass:"bers-form-input__input bers-form-input__input--email",class:[
          { 'bers-form-input__input--error': _vm.isError2 },
          { 'bers-form-input__input--empty': _vm.isEmptyMail2 }
        ],attrs:{"type":"email","pattern":_vm.properties.validation.pattern,"id":"email2","placeholder":_vm.properties.text,"required":_vm.mandatory},domProps:{"value":(_vm.email2)},on:{"input":function($event){if($event.target.composing)return;_vm.email2=$event.target.value}}}),_c('label',{staticClass:"bers-form-input__label",attrs:{"for":"email2"},domProps:{"innerHTML":_vm._s(_vm.i18n('confirmEmail'))}}),(_vm.isError2)?_c('small',{staticClass:"bers-form-input__error-text",domProps:{"textContent":_vm._s(_vm.getErrorText2)}}):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }