<template>
  <div>
    <div class="bers" :class="[isLoading ? 'bers--loading' : '']">
      <div class="bers-steps">
        <form class="bers__form" v-if="status === 'mounted'" @submit="onSubmit">
          <ol
            class="bers-stepper"
            :class="[
              { 'bers-stepper--first-step': isBuyAble },
              { 'bers-stepper--second-step': isPayAble },
              { 'bers-stepper--third-step': isBought }
            ]"
          >
            <li
              class="bers-stepper__step"
              :class="[isBuyAble ? 'bers__stepper_step--active' : '']"
            >
              <dl class="bers-stepper__step-content">
                <dt class="bers-stepper__step-number">1</dt>
                <dd class="bers-stepper__step-title">{{ i18n("product") }}</dd>
              </dl>
            </li>
            <li
              class="bers-stepper__step"
              :class="[isPayAble ? 'bers__stepper_step--active' : '']"
            >
              <dl class="bers-stepper__step-content">
                <dt class="bers-stepper__step-number">2</dt>
                <dd class="bers-stepper__step-title">{{ i18n("payment") }}</dd>
              </dl>
            </li>
            <li
              class="bers-stepper__step"
              :class="[isBought ? 'bers__stepper_step--active' : '']"
            >
              <dl class="bers-stepper__step-content">
                <dt class="bers-stepper__step-number">3</dt>
                <dd class="bers-stepper__step-title">
                  {{ i18n("confirmation") }}
                </dd>
              </dl>
            </li>
          </ol>
          <div :class="isBuyAble ? '' : 'bers--hidden'">
            <Product></Product>
          </div>
          <Payment v-if="isPayAble"></Payment>
          <Confirmation v-if="isBought"></Confirmation>
        </form>
        <div v-else-if="status === 'loading'" class="bers-p-fieldset">
          <h2>{{ i18n("loading") }}</h2>
        </div>
        <div
          v-else-if="status === 'error'"
          class="bers-global-error bers-p-fieldset"
        >
          <div class="bers-global-error__button-wrapper">
            <div class="bers-global-error__icon-wrapper">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="44"
                height="44"
                viewBox="0 0 44 44"
              >
                <g transform="translate(1 1)">
                  <circle
                    cx="21"
                    cy="21"
                    r="21"
                    fill="none"
                    stroke="#e61900"
                    stroke-miterlimit="10"
                    stroke-width="2"
                  />
                  <g transform="translate(16.435 9.13)">
                    <path
                      d="M4.261,4.261H0V0H4.261Z"
                      transform="translate(2.347 0)"
                      fill="#e61900"
                    />
                    <path
                      d="M9.038,16.428h-9V14.6l2.347-.383V2.347L0,2.08V.549L6.724,0V14.214l2.314.383Z"
                      transform="translate(0 7.124)"
                      fill="#e61900"
                    />
                  </g>
                </g>
              </svg>
            </div>
          </div>
          <h3 class="bers-global-error__title">
            {{ i18n("errorAppConfiguration") }}
          </h3>
        </div>
        <div v-else class="bers-global-error bers-p-fieldset">
          <div class="bers-global-error__button-wrapper">
            <button class="bers-global-error__button" @click="pageReload()">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="42.857"
                viewBox="0 0 50 42.857"
              >
                <path
                  id="Shape"
                  d="M4.328,24.507H.828a.835.835,0,0,1-.646-1.355l4.9-6.172a.825.825,0,0,1,1.165-.13.8.8,0,0,1,.129.13l4.9,6.172a.839.839,0,0,1-.13,1.173.827.827,0,0,1-.517.183H7.689A18.266,18.266,0,0,0,25.613,39.521a18.072,18.072,0,0,0,12.105-4.633,1.649,1.649,0,0,1,2.339.139,1.675,1.675,0,0,1-.137,2.354,21.374,21.374,0,0,1-14.307,5.477A21.59,21.59,0,0,1,4.328,24.507ZM43.821,27.2a.823.823,0,0,1-.128-.129l-4.847-6.066a.819.819,0,0,1,.639-1.331h3.136A18.015,18.015,0,0,0,11.2,9.347,1.639,1.639,0,1,1,8.746,7.17,21.29,21.29,0,0,1,45.909,19.676h3.272a.819.819,0,0,1,.639,1.331l-4.848,6.066a.818.818,0,0,1-1.151.129Z"
                  fill="#e61900"
                />
              </svg>
            </button>
          </div>
          <h3 class="bers-global-error__title">{{ i18n("blockingError") }}</h3>
          <p class="bers-global-error__text">{{ i18n("errorBuying") }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Product from "./components/Product.vue";
import Payment from "./components/Payment.vue";
import Confirmation from "./components/Confirmation.vue";

export default {
  name: "berservices",
  props: {
    category: {
      default: 0
    }
  },
  data() {
    return {
      status: "loading"
    };
  },
  components: {
    Product,
    Payment,
    Confirmation
  },
  methods: {
    pageReload() {
      location.reload();
      return false;
    },
    onSubmit(e) {
      const isInDropinClient = e.submitter?.closest(".adyen-checkout__dropin");
      // Safari fallback: https://caniuse.com/mdn-api_submitevent_submitter
      const hasDropinClient = document.querySelector(".adyen-checkout__dropin");
      if (isInDropinClient || hasDropinClient) {
        e.preventDefault();
      }
    }
  },
  computed: {
    isBuyAble() {
      return this.$store.getters.getIsBuyAble;
    },
    isPayAble() {
      return this.$store.getters.getIsPayAble;
    },
    isBought() {
      return this.$store.getters.getIsBought;
    },
    getGlobalError() {
      return this.$store.getters.getGlobalError;
    },
    getDataFromStore() {
      return this.$store.getters.getData;
    },
    isLoading() {
      return this.$store.getters.getLoading;
    },
    getErrorText() {
      return this.$store.getters.getData.errortext.text;
    }
  },
  watch: {
    getDataFromStore() {
      if (this.getDataFromStore && this.getDataFromStore.category) {
        this.status = "mounted";
      } else {
        this.status = "error";
        this.$store.commit("update_loading", false);
      }
    },
    getGlobalError() {
      this.scrollTopBers();
      this.status = this.getGlobalError;
    }
  },
  mounted() {
    this.$store.dispatch("GET_DATA", {
      route: "products",
      parameter: {
        category_id: this.category
      },
      i18n: this.i18n
    });
  }
};
</script>

<style lang="scss">
.bers *,
.bers *::before,
.bers *::after {
  box-sizing: border-box;
}
.bers {
  color: $color-bers-grey-dark;
  display: flex;
  font-family: "BERSans", sans-serif;
  overflow-x: hidden;
  padding: 60px 0 40px;
  -webkit-font-smoothing: antialiased;

  .bers-steps {
    width: 100%;
  }
  &--hidden {
    display: none;
  }

  &--loading {
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    min-height: 100vh;
    opacity: 0.5;
  }

  @keyframes pulsate {
    0% {
      opacity: 0.5;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.5;
    }
  }

  .button-wrapper {
    margin: 2rem 0;
    padding-left: 40px;
  }

  .bers-stepper {
    display: flex;
    list-style: none;
    margin: 0 0 50px;
    padding: 0 25px;

    &.bers-stepper--second-step {
      transform: translateX(-230px);
    }

    &.bers-stepper--third-step {
      transform: translateX(-460px);
    }

    @media screen and (min-width: $media-width--tablet) {
      padding: 0 40px;

      &.bers-stepper--second-step,
      &.bers-stepper--third-step {
        transform: none;
      }
    }

    .bers-stepper__step {
      color: $color-bers-grey-medium;
      display: block;
      flex: 1 0 220px;
      font-size: 24px;
      margin: 0 10px 0 0;
      min-width: 220px;
      padding: 12px 20px 20px 0;
      position: relative;
      outline: 0;
      text-decoration: none;
      width: 100%;
    }

    .bers-stepper__step.bers__stepper_step--active {
      color: $color-bers-grey-dark;
    }

    .bers-stepper__step::after {
      background-color: $color-bers-grey-light;
      bottom: 0;
      content: "";
      height: 8px;
      left: 0;
      position: absolute;
      width: 100%;
    }

    .bers-stepper__step.bers__stepper_step--active::after {
      background-color: $color-bers-red;
    }

    .bers-stepper__step-content {
      align-items: flex-end;
      display: flex;
      margin: 0;
    }

    .bers-stepper__step-number {
      display: block;
      font-size: 64px;
      line-height: 54px;
      margin-right: 16px;
    }

    .bers-stepper__step-title {
      display: block;
      font-size: 24px;
      line-height: 26px;
      margin: 0;
    }
  }
}

.bers-global-error {
  color: $color-bers-grey-medium;
  text-align: center;
  .bers-global-error__button-wrapper {
    .bers-global-error__icon-wrapper,
    .bers-global-error__button {
      padding: 10px;
      margin: 10px 0;
    }

    .bers-global-error__button {
      background: transparent;
      border: none;
      cursor: pointer;
      outline: none;
    }
  }

  .bers-global-error__title {
    font-weight: bold;
  }
}
</style>
