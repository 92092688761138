<template>
  <div class="bers-product">
    <!--<h2 class="bers-product__headline">{{ i18n("product") }}</h2>-->
    <productselect></productselect>
    <div v-for="(type, index, key) in productTypes" :index="index" :key="key">
      <producttype
        v-if="type.identifier === 'entry_type'"
        v-bind="type"
      ></producttype>
    </div>
    <producttime></producttime>
    <productcustomer></productcustomer>
  </div>
</template>

<script>
import Vue from "vue";
import ProductSelect from "./product/ProductSelect";
import ProductTime from "./product/ProductTime";
import ProductType from "./product/ProductType";
import ProductCustomer from "./product/ProductCustomer";

Vue.component("productselect", ProductSelect);
Vue.component("producttime", ProductTime);
Vue.component("producttype", ProductType);
Vue.component("productcustomer", ProductCustomer);

export default {
  name: "Product",
  computed: {
    productTypes() {
      return this.$store.getters.getProductTypes;
    }
  }
};
</script>

<style scoped></style>
