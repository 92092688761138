import Vue from "vue";
import App from "./App.vue";
import store from "./store";

import "./assets/styles/global.scss";

Vue.config.productionTip = false;

let appEntry = "#berservice";
if (!document.querySelector(appEntry)) {
  appEntry = "#app";
}

let lang = "";
if (document.querySelector(appEntry).dataset.lang) {
  lang = document.querySelector(appEntry).dataset.lang.substr(0, 2);
} else if (document.querySelector("html").getAttribute("lang")) {
  lang = document
    .querySelector("html")
    .getAttribute("lang")
    .substr(0, 2);
}

if (lang !== "de" && lang !== "en") {
  lang = "de"; // default language
}

import de from "./lang/de.json";
import en from "./lang/en.json";
const i18nTexts = {
  de,
  en
};

if (document.querySelector(appEntry).dataset.usefonts) {
  const nodes = document.querySelectorAll("script");
  const nodeArr = Array.from(nodes);
  const nodeScript = nodeArr.find(node => node.src.includes("berservices.js"));
  const scriptUrl = nodeScript.src.substr(0, nodeScript.src.lastIndexOf("/"));

  const sheet = window.document.styleSheets[0];
  const styles = [
    `@font-face {
    font-family: 'BERSans';
    src: url('${scriptUrl}/fonts/BERWeb-Regular.woff2') format('woff2'), url('${scriptUrl}/fonts/BERWeb-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }`,
    `@font-face {
    font-family: 'BERSans';
    src: url('${scriptUrl}/fonts/BERWeb-Bold.woff2') format('woff2'), url('${scriptUrl}/fonts/BERWeb-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: block;
    text-rendering: optimizeLegibility;
  }`
  ];
  for (let index = 0; index < styles.length; index++) {
    sheet.insertRule(styles[index], sheet.cssRules.length);
  }
}
Vue.mixin({
  methods: {
    i18n(key) {
      if (!key) {
        return lang;
      } else if (i18nTexts[lang][key]) {
        return i18nTexts[lang][key];
      } else if (i18nTexts["de"][key]) {
        return "(" + key + " #missing in " + lang + ")";
      } else {
        return "(" + key + " #missing in " + lang + ")";
      }
    },
    getLang() {
      return lang;
    },
    scrollTopBers() {
      document.querySelector(".bers").scrollIntoView({ behavior: "smooth" });
    }
  }
});

if (typeof fetch === "function") {
  new Vue({
    store,
    render: h =>
      h(App, {
        props: {
          category: document.querySelector(appEntry).dataset.category
        }
      })
  }).$mount(appEntry);
} else {
  document.querySelector(appEntry).innerHTML = `<div class="bers">
    <div class="bers-global-error">
        <div class="bers-global-error__icon-wrapper">
            <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44">
                <g transform="translate(1 1)">
                    <circle cx="21" cy="21" r="21" fill="none" stroke="#e61900" stroke-miterlimit="10" stroke-width="2"/>
                    <g transform="translate(16.435 9.13)">
                        <path d="M4.261,4.261H0V0H4.261Z" transform="translate(2.347 0)" fill="#e61900"/>
                        <path d="M9.038,16.428h-9V14.6l2.347-.383V2.347L0,2.08V.549L6.724,0V14.214l2.314.383Z" transform="translate(0 7.124)" fill="#e61900"/>
                    </g>
                </g>
            </svg>
        </div>
        <h3 class="bers-global-error__title">${i18nTexts[lang]["errorNotSupportedBrowser"]}</h3>
    </div>
    </div>
    <style>
        .bers {
            margin: 0 auto;
            max-width: 800px;
            padding: 60px 40px 40px;
        }
        
        .bers-global-error__icon-wrapper {
            padding: 10px;
        }
    </style>`;
}
