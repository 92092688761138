<template>
  <fieldset class="bers-product-type bers-p-fieldset">
    <intro :headline="properties.title" :introText="properties.text"></intro>
    <toggle :items="properties.values"></toggle>
  </fieldset>
</template>

<script>
import Vue from "vue";
import Intro from "../text/Intro";
import Toggle from "../form/Toggle";

Vue.component("intro", Intro);
Vue.component("toggle", Toggle);

export default {
  name: "ProductType",
  data() {
    return {
      headline: "headlineProductPrice",
      introText: "infoProductPrice"
    };
  },
  props: {
    identifier: {
      type: String,
      default: "identifier"
    },
    mandatory: {
      type: Boolean,
      default: false
    },
    properties: {
      title: {
        type: String,
        default: "title"
      },
      text: {
        type: String,
        default: "text"
      },
      error_title: {
        type: String,
        default: "errorTitle"
      },
      error_text: {
        type: String,
        default: "errorText"
      },
      values: Array
    }
  },
  mounted() {
    this.headline = this.i18n(this.headline);
    this.introText = this.i18n(this.introText);
  }
};
</script>

<style lang="scss">
.bers {
  .bers-product-type {
    margin-bottom: 50px;
  }
}
</style>
