<template>
  <fieldset
    class="bers-product-customer bers-p-fieldset"
    :class="[statusAvailability ? '' : 'bers-fieldset--deactived']"
  >
    <intro
      :headline="headline"
      :introText="introText"
      highlighted="true"
    ></intro>
    <!--<p v-if="error" class="bers-product-customer__error-text">
      {{ errorForm }}
    </p>-->
    <div class="bers-product-customer__input-wrapper">
      <component
        v-for="(input, key, index) in productInputsData"
        :is="getFieldComponentName(input.type)"
        :index="index"
        :key="key"
        :backendErrorMessage="backendErrorMessage"
        v-bind="input"
      />
    </div>
    <productselectsummary></productselectsummary>
    <button
      class="bers-product-customer__submit-button bers-p-button bers-p-button--light"
      :class="{ 'bers-p-button--disabled': statusAvailability === false }"
      @click.stop.prevent="gotToPayment"
    >
      <span>{{ i18n("buttonGoToPayment") }}</span>
    </button>
  </fieldset>
</template>

<script>
import Vue from "vue";
import Intro from "../text/Intro";
import Input from "../form/Input";
import Checkbox from "../form/Checkbox";
import Coupon from "../form/Coupon";
import Email from "../form/Email";
import ProductSelectSummary from "./ProductSelectSummary";
import callBerServiceApi from "@/js/services/berServices";

Vue.component("intro", Intro);
Vue.component("customerInput", Input);
Vue.component("customerCheckbox", Checkbox);
Vue.component("customerCoupon", Coupon);
Vue.component("customerEmail", Email);
Vue.component("productselectsummary", ProductSelectSummary);
export default {
  name: "ProductCustomer",
  props: {},
  data() {
    return {
      headline: "titleCustomerInputs",
      error: false,
      errorForm: "errorFormCustomer",
      introText: "infoFormCustomer",
      productBuyabilityChecked: false,
      hasToken: false,
      productInputsData: [],
      backendErrorMessage: null
    };
  },
  mounted() {
    this.headline = this.i18n(this.headline);
    this.errorForm = this.i18n(this.errorForm);
    this.introText = this.i18n(this.introText);

    const fields = this.$store.getters.customerInputs;
    for (let index = 0; index < fields.length; index++) {
      const data = Object.assign({}, fields[index]);
      data.errorstatus = false;
      this.productInputsData.push(data);
      this.$store.commit("update_customer", {
        key: fields[index].identifier,
        value: ""
      });
    }
  },
  computed: {
    errorstatuswatch() {
      return this.errorstatus;
    },
    statusAvailability() {
      return this.$store.getters.getBuyAbleChecked;
    }
  },
  methods: {
    gotToPayment() {
      const form = document.querySelector(".bers__form");
      this.backendErrorMessage = null;
      const fields = this.productInputsData;
      for (let index = 0; index < fields.length; index++) {
        const identifier = fields[index].identifier;
        const element = form.querySelector("#" + identifier);
        this.productInputsData[index].errorstatus = !element.checkValidity();
      }

      // important to force on back button
      this.hasToken = false;
      this.productBuyabilityChecked = false;

      if (!form.checkValidity()) {
        this.error = true;
        setTimeout(function() {
          document
            .querySelector(".bers-product-customer")
            .scrollIntoView({ behavior: "smooth" });
        }, 200);
      } else if (this.$store.getters.getAmount > 0) {
        this.error = false;
        //this.getTokenResponse();
        this.hasToken = true;
        this.checkproductBuyability();
        this.$store.commit("update_loading", true);
      } else {
        this.error = false;
        this.hasToken = true;
        this.checkproductBuyability();
        this.$store.commit("update_loading", true);
      }
    },
    getProductBuyability(response) {
      if (response.loading) {
        //
      } else {
        if (response.product_buyability) {
          this.scrollTopBers();
          if (this.$store.getters.getAmount === 0) {
            this.$store.commit("update_setBuyAble", false);
            this.$store.commit("update_setPayAble", true);
          } else {
            this.productBuyabilityChecked = response.product_buyability;
          }
        } else {
          // TODO: Error
          this.$store.commit("update_loading", false);
          if (
            response.error &&
            (response.error.code === "generalError" || response.status === 500)
          ) {
            this.$store.commit("update_setGlobalError", response.error.text);
          } else if (response.error && response.error.identifier) {
            document
              .querySelector(".bers-product-customer")
              .scrollIntoView({ behavior: "smooth" });
            this.backendErrorMessage = response.error.text;
            this.productInputsData[
              this.productInputsData.findIndex(
                el => el.identifier === response.error.identifier
              )
            ].errorstatus = true;
          } else {
            this.$store.commit("update_setGlobalError", response.error);
            console.log("getProductBuyability.response Error", response);
          }
        }
      }
    },
    checkproductBuyability() {
      this.$store.commit("update_loading", true);

      callBerServiceApi(
        "productBuyability",
        this.$store.getters.getProductData,
        this.getProductBuyability,
        this.i18n
      );
    },
    getToken(response) {
      if (response.loading) {
        //
      } else {
        if (response.data && response.data.token) {
          this.$store.commit("update_token", response.data.token);
          this.hasToken = true;
        } else {
          if (response.error && response.error.text) {
            console.log(" response.error.text");
            this.$store.commit("update_setGlobalError", response.error.text);
          } else if (response.error) {
            console.log(" response.error");
            this.$store.commit("update_setGlobalError", response.error);
          }
          console.log("getToken.response ERROR", response);
        }
      }
    },
    getTokenResponse() {
      this.$store.commit("update_loading", true);
      callBerServiceApi(
        "token",
        { id: this.$store.getters.getProductData.product_id },
        this.getToken,
        this.i18n
      );
    },
    gotPaying() {
      this.$store.commit("update_setBuyAble", false);
      this.$store.commit("update_setPayAble", true);
    },
    getFieldComponentName(inputType) {
      switch (inputType) {
        case "checkbox":
          return "customerCheckbox";
        case "coupon":
          return "customerCoupon";
        case "email":
          return "customerEmail";
        default:
          return "customerInput";
      }
    }
  },
  watch: {
    // whenever question changes, this function will run
    productInputs() {
      console.log("watchInputs", this.$store.getters.customerInputs);
    },
    productBuyabilityChecked() {
      if (this.hasToken === true) {
        this.gotPaying();
      }
    },
    hasToken() {
      if (this.productBuyabilityChecked === true) {
        this.gotPaying();
      }
    }
  }
};
</script>

<style lang="scss">
.bers {
  .bers-product-customer__input-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .bers-product-customer__submit-button {
    margin: 0 auto;
    max-width: 325px;
    width: 100%;

    &.bers-product-customer__submit-button--disabled {
      pointer-events: none;
      border-color: $color-bers-grey-light;
      color: $color-bers-grey-light;
    }
  }
}
</style>
