<template>
  <div class="increment">
    <div class="increment__info-wrapper">
      <span v-text="text"></span>
    </div>
    <div class="increment__input-wrapper">
      <p class="increment__text" v-text="text"></p>
      <label class="increment__label" :for="identifier" v-text="title"></label>
      <input
        class="increment__input"
        type="number"
        value="1"
        :id="identifier"
        :min="min"
        :max="checkMax()"
        @input="handleInput($event)"
        @blur="setZero($event)"
      />
      <div class="increment__error">
        <small class="increment__error-title" v-text="error_title"></small>
        <small class="increment__error-text" v-text="error_text"></small>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentIndex: 0,
      newMax: this.max
    };
  },
  props: {
    info: {
      type: Object,
      productTitle: {
        type: String,
        default: "No data"
      },
      productInfo: {
        type: String,
        default: "No data"
      }
    },
    identifier: {
      type: String,
      default: "number_tickets"
    },
    type: {
      type: String,
      default: "number"
    },
    mandatory: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: "title"
    },
    text: {
      type: String,
      default: "text"
    },
    error_title: {
      type: String,
      default: "errorTitle"
    },
    error_text: {
      type: String,
      default: "errorText"
    },
    min: {
      type: Number,
      default: 1
    },
    max: {
      type: Number,
      default: 10
    }
  },
  computed: {},
  methods: {
    setZero(event) {
      let element = event.currentTarget;
      if (element.value === "") {
        element.value = 1;
      }
    },
    checkMax() {
      return this.max;
    },
    handleInput: function(event) {
      let element = event.currentTarget;
      if (+element.value > +element.max) {
        element.value = element.max;
      }
      this.handleValue(element);
    },
    handleValue: function(element) {
      this.$store.commit("update_" + element.id, parseInt(element.value));
    }
  }
};
</script>

<style scoped>
.increment {
  border: 1px solid #000;
  padding: 2rem;
}

.increment__info-wrapper {
  color: #42b983;
  width: 50%;
}

.increment__input-wrapper {
  width: 50%;
}

.increment__input {
  width: 200px;
}

.increment__label {
  display: block;
  margin: 0.5rem 0;
}

.increment__error {
  display: none;
  color: red;
  margin: 0.5rem 0;
}

.increment__error-title,
.increment__error-text {
  display: block;
}
</style>
