<template>
  <div class="bers-form-double-input">
    <div class="bers-form-input">
      <div class="bers-form-input__wrapper">
        <input
          class="bers-form-input__input bers-form-input__input--email"
          :class="[
            { 'bers-form-input__input--error': isError },
            { 'bers-form-input__input--empty': isEmptyMail }
          ]"
          type="email"
          :pattern="properties.validation.pattern"
          :placeholder="properties.text"
          :id="identifier"
          :required="mandatory"
          v-model="email"
        />
        <label
          class="bers-form-input__label"
          :for="identifier"
          v-html="properties.title"
        >
        </label>
        <small
          v-if="isError"
          class="bers-form-input__error-text"
          v-text="getErrorText1"
        ></small>
      </div>
    </div>
    <div class="bers-form-input">
      <div class="bers-form-input__wrapper">
        <input
          class="bers-form-input__input bers-form-input__input--email"
          :class="[
            { 'bers-form-input__input--error': isError2 },
            { 'bers-form-input__input--empty': isEmptyMail2 }
          ]"
          type="email"
          :pattern="properties.validation.pattern"
          id="email2"
          v-model="email2"
          :placeholder="properties.text"
          :required="mandatory"
        />
        <label
          class="bers-form-input__label"
          for="email2"
          v-html="i18n('confirmEmail')"
        >
        </label>
        <small
          v-if="isError2"
          class="bers-form-input__error-text"
          v-text="getErrorText2"
        ></small>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Input",
  props: {
    identifier: {
      type: String,
      default: "identifier"
    },
    errorstatus: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: "text"
    },
    mandatory: {
      type: Boolean,
      default: false
    },
    properties: {
      title: {
        type: String,
        default: "title"
      },
      text: {
        type: String,
        default: "text"
      },
      error_title: {
        type: String
      },
      error_text: {
        type: String
      },
      validation: {
        type: Object
      }
    },
    backendErrorMessage: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      error: false,
      error2: false,
      email: "",
      email2: "",
      isEmptyMail: true,
      isEmptyMail2: true,
      timerId: null,
      elMail: null,
      elMail2: null,
      errorMsg: "",
      errorMsg2: ""
    };
  },
  mounted() {
    this.elMail = document.querySelector("#" + this.identifier);
    this.elMail2 = document.querySelector("#email2");
    this.validateMails();
  },
  watch: {
    email: function() {
      this.debounce(this.validateMails, 800);
    },
    email2: function() {
      this.debounce(this.validateMails, 800);
    }
  },
  computed: {
    isError() {
      return this.errorstatus && this.error;
    },
    isError2() {
      return this.errorstatus && this.error2;
    },
    getErrorText1() {
      if (this.isError) {
        return this.backendErrorMessage || this.errorMsg;
      }
      return "";
    },
    getErrorText2() {
      if (this.isError2) {
        return this.errorMsg2;
      }
      return "";
    }
  },
  methods: {
    debounce(func, wait) {
      clearTimeout(this.timerId);
      this.timerId = setTimeout(func, wait);
    },
    validateMails() {
      this.validateMail();
      this.validateMail2();
      if (this.error2) {
        this.setCustomValidity(this.i18n("errorEmailsNotIdentical"));
      } else {
        this.setCustomValidity("");
        this.commitValue();
      }
    },
    setCustomValidity(msg) {
      const mailInput = document.querySelector("#" + this.identifier);
      mailInput.setCustomValidity(msg);
    },
    validateMail() {
      this.isEmptyMail = this.elMail.value.trim() === "";
      this.patternMatch = !this.elMail.validity.patternMismatch;
      if (this.elMail.validity.valueMissing) {
        this.error = true;
        this.errorMsg = this.properties.error_text;
        return;
      }
      if (
        !this.elMail.validity.valueMissing &&
        (this.elMail.validity.typeMismatch ||
          this.elMail.validity.patternMismatch)
      ) {
        this.error = true;
        this.errorMsg = this.properties.error_text;
        return;
      }
      this.error = false;
      this.errorMsg = "";
    },
    validateMail2() {
      this.isEmptyMail2 = this.elMail2.value.trim() === "";
      if (this.elMail2.validity.valueMissing) {
        this.error2 = true;
        this.errorMsg2 = this.properties.error_text;
        return;
      }
      if (
        !this.elMail2.validity.valueMissing &&
        (this.elMail2.validity.typeMismatch ||
          this.elMail2.validity.patternMismatch)
      ) {
        this.error2 = true;
        this.errorMsg2 = this.properties.error_text;
        return;
      }
      if (this.email !== this.email2) {
        this.error2 = true;
        this.errorMsg2 = this.i18n("errorEmailsNotIdentical");
        return;
      }
      this.error2 = false;
      this.errorMsg2 = "";
    },
    commitValue() {
      this.$store.commit("update_customer", {
        key: this.identifier,
        value: this.email
      });
    }
  }
};
</script>

<style lang="scss">
.bers-form-double-input {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}
</style>
