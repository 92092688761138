<template>
  <tr class="bers-product-select-price">
    <!--<div>
      <h2 class="bers__product_h2">{{ data.product_price_name }}</h2>
      <p class="bers__product_text">{{ data.product_price_desc }}</p>
      &lt;!&ndash; <p>Id: {{ data.product_price_id }}</p> &ndash;&gt;
    </div>-->
    <td class="bers-product-select-price__name">
      <span v-text="data.product_price_name"></span>
    </td>
    <td class="bers-product-select-price__price">
      <span v-text="data.product_price_str"></span>
    </td>
    <td class="bers-product-select-price__amount">
      <div
        v-for="(item, index, key) in getPriceInputs"
        :index="index"
        :key="key"
      >
        <div v-if="item.type === 'integer'">
          <stepper
            :item="item"
            :number="number"
            class="bers__product_stepper"
          ></stepper>
        </div>
      </div>
    </td>
    <!--<p>String {{ data.product_price_str }}</p>-->
    <!--<p>Price {{ data.product_price_price }}</p>-->
    <!-- <increment v-bind="getProductQuantity"></increment> -->
    <!-- {{ getPriceInputs }} -->
  </tr>
</template>

<script>
import Vue from "vue";
import Stepper from "../form/Stepper";

Vue.component("stepper", Stepper);

export default {
  props: {
    data: {
      type: Object
    },
    number: {
      type: Number
    }
  },
  computed: {
    getProductQuantity() {
      return 1;
    },
    getPriceInputs() {
      return this.$store.getters.getPriceInputs;
    }
  },
  mounted() {
    this.$store.commit("update_product_prices", {
      index: this.number,
      identifier: "product_price_id",
      value: this.data.product_price_id
    });
  }
};
</script>

<style lang="scss">
.bers-product-select-price__name {
  font-size: 24px;
  font-weight: bold;
  line-height: 34px;
  @media screen and (min-width: $media-width--tablet) {
    width: 60%;
  }
}

.bers-product-select-price__price {
  font-size: 18px;
  font-weight: bold;
  line-height: 22px;
}

.bers-product-select-price__amount {
  width: 100px;
}
</style>
